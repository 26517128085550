import React from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SearchIcon from '../../../../assets/img/search-icon-grey.svg';
import { DropdownSelect } from '../../../../shared/Components';
import CustomDateRangePicker from '../../../../shared/Components/CustomDateRangePicker';
import DropdownMaterial from '../../../../shared/Components/DropdownMaterial/DropdownMaterial';
import DropdownState from '../../../../shared/Components/DropdownState/DropdownState';
import TooltipOnHover from '../../../../shared/Components/TooltipOnHover';
import { toLocalDate } from '../../../../shared/Utils/Helper';

function renderColumn(
  param,
  filterValue,
  setFilter,
  serviceProviders,
  recyclers,
  dropdownOptions,
  usersList
) {
  const searchData = (event) => {
    const value = event.target.value;
    setFilter(value);
  };

  const debounce = (callback, waitTime) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(...args);
      }, waitTime);
    };
  };

  const debounceHandler = (e) => debounce(searchData(e), 1000);
  switch (param) {
    case 'batchId':
      return (
        <InputGroup maxHeight="26px">
          <Form.Control
            value={filterValue || ''}
            onChange={(e) => debounceHandler(e)}
            placeholder="Search"
            className="column-filter-input"
          />
          <InputGroup.Text className="column-filter-input-icon">
            <img src={SearchIcon} width="15px" height="15px" alt="search" />
          </InputGroup.Text>
        </InputGroup>
      );
    case 'referenceId':
      return (
        <InputGroup maxHeight="26px">
          <FormControl
            placeholder="Search by Draft DL"
            type="number"
            value={filterValue || ''}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            onKeyDown={(event) => {
              const invalidCharacters = ['-', '+', 'e'];
              if (invalidCharacters.includes(event?.key)) {
                event.preventDefault();
              }
            }}
            min={0}
          />
        </InputGroup>
      );
    case 'recyclerName':
      return (
        <DropdownSelect
          options={recyclers}
          getOptionLabel={(option) => `${option?.businessName}`}
          getOptionValue={(option) => `${option?.customerId}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.customerId);
          }}
          value={filterValue || ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
          valueKey="customerId"
          maxHeight="26px"
        />
      );
    case 'serviceProviderName':
      return (
        <DropdownSelect
          options={serviceProviders?.filter((sp) => sp?.vendorType === 'SERVICE_PROVIDER')}
          getOptionLabel={(option) => `${option?.businessName}`}
          getOptionValue={(option) => `${option?.id}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.id);
          }}
          value={filterValue || ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
          maxHeight="26px"
        />
      );
    case 'itemId':
      return (
        <DropdownMaterial
          value={filterValue || null}
          onChange={(option) => setFilter(option?.id)}
          filterIds={dropdownOptions?.length ? dropdownOptions : ['']}
          placeholder="Select Material"
          isClearable
        />
      );
    case 'stateId':
      return (
        <DropdownState
          value={filterValue || null}
          onChange={(option) => setFilter(option?.id)}
          filterIds={dropdownOptions?.length ? dropdownOptions : ['']}
          isD1={true}
          placeholder="Select State"
          isClearable
        />
      );
    case 'userName':
      return (
        <DropdownSelect
          options={usersList}
          getOptionLabel={(option) => `${option?.name}`}
          getOptionValue={(option) => `${option?.id}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.id);
          }}
          value={filterValue || ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
          maxHeight="26px"
        />
      );
    case 'uploadedAt':
      return filterValue?.[0] && filterValue?.[1] ? (
        <>
          <TooltipOnHover
            dataStyle={{ color: '#005dff', cursor: 'pointer' }}
            msg={`${toLocalDate(filterValue[0])} to ${toLocalDate(filterValue[1])}`}>
            <CustomDateRangePicker
              parentEl="#orderDate"
              id="orderDate"
              initialSettings={{
                autoUpdateInput: false,
                locale: {
                  cancelLabel: 'Clear',
                },
                maxDate: new Date(),
                alwaysShowCalendars: false,
              }}
              startDate={filterValue ? filterValue[0] : ''}
              endDate={filterValue ? filterValue[1] : ''}
              placeholder="Select"
              onApply={({ startDate, endDate }, $event) => {
                setFilter([startDate, endDate]);
              }}
              onCancel={(event, picker) => {
                setFilter(undefined);
              }}
              setFilter={setFilter}
              inputClass="custom-datepicker"
            />
          </TooltipOnHover>
        </>
      ) : (
        <>
          <CustomDateRangePicker
            parentEl="#orderDate"
            id="orderDate"
            initialSettings={{
              autoUpdateInput: false,
              locale: {
                cancelLabel: 'Clear',
              },
              maxDate: new Date(),
              alwaysShowCalendars: false,
            }}
            startDate={filterValue ? filterValue[0] : ''}
            endDate={filterValue ? filterValue[1] : ''}
            placeholder="Select"
            onApply={({ startDate, endDate }, $event) => {
              setFilter([startDate, endDate]);
            }}
            onCancel={(event, picker) => {
              setFilter(undefined);
            }}
            setFilter={setFilter}
            inputClass="custom-datepicker"
          />
        </>
      );
    case 'originalFileName':
      return (
        <InputGroup maxHeight="26px">
          <Form.Control
            value={filterValue || ''}
            onChange={(e) => debounceHandler(e)}
            placeholder="Search"
            className="column-filter-input"
          />
          <InputGroup.Text className="column-filter-input-icon">
            <img src={SearchIcon} width="15px" height="15px" alt="search" />
          </InputGroup.Text>
        </InputGroup>
      );
    default:
      return null;
  }
}

function ColumnFilters({ column }) {
  const { filterValue, setFilter, dropdownOptions, recyclers } = column;

  const { serviceProviders, usersList } = useSelector(({ serviceProvider, usersList }) => ({
    serviceProviders: serviceProvider.listLight,
    usersList: usersList?.data,
  }));

  return (
    <span>
      {renderColumn(
        column?.id,
        filterValue,
        setFilter,
        serviceProviders || [],
        recyclers || [],
        dropdownOptions || [],
        usersList || []
      )}
    </span>
  );
}

export default ColumnFilters;
