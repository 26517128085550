import classNames from 'classnames';
import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = (props) => {
  return (
    <div className={classNames('animated fadeIn pt-3 text-center', props.className)}>
      <Spinner
        as="span"
        animation="border"
        size={props.size || 'lg'}
        role="status"
        variant={props.variant || 'primary'}
        aria-hidden="true"
      />
      <h5 className="mt-2">{!props.hideText && (props.text ? props.text : 'Loading')}</h5>
    </div>
  );
};

export default Loader;
