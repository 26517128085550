import { faCheck, faEllipsisV, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ReactComponent as TruckIcon } from '../../../assets/img/_App/transicon.svg';
import DeliveryDocument from '../../../features/_App/Delivery/DeliveryDocument/DeliveryDocument';
import DeliveryDocGeneratedSuccessView from '../../../features/_App/Delivery/DeliveryDocumentsGenerate/DeliveryDocGeneratedSuccessView';
import DeliveryInvoiceDocGeneratedSuccessView from '../../../features/_App/Delivery/DeliveryDocumentsGenerate/DeliveryInvoiceDocGeneratedSuccessView';
import DeliveryLocationsDetail from '../../../features/_App/Delivery/DeliveryLocations/DeliveryLocationsDetail';
import DeliverySupervisorDetails from '../../../features/_App/Delivery/DeliverySupervisor/DeliverySupervisorDetails';
import DeliveryTranporterDetails from '../../../features/_App/Delivery/DeliveryTransorter/DeliveryTranporterDetails';
import SupervisorPickupDetails from '../../../features/_App/Delivery/SupervisorPickupDetails';
import DeliveryEditAndDeleteOptionModal from '../../../features/_App/Modal/DeliveryEditAndDeleteOptionModal';
import DeliverySubmitForApprovalModal from '../../../features/_App/Modal/DeliverySubmitForApprovalModal';
import { DeliveryService } from '../../../features/Delivery/delivery.service';
import ConfirmDeliveryModal from '../../../features/Delivery/DeliveryView/ConfirmDeliveryModal';
import DocumentHistoryContainer from '../../../features/Delivery/DocumentHistory';
import { useAsyncEffect } from '../../../hooks';
import { useTracking, useTransporter } from '../../../hooks/hooks';
import { useAccount } from '../../../hooks/useAccount';
import AppHeader from '../../../layout/AppHeader';
import FormField from '../../../shared/Components/_App/FormField';
import FormLabel from '../../../shared/Components/_App/FormLabel';
import LoadingButton from '../../../shared/Components/_App/LoadingButton/LoadingButton';
import PageView from '../../../shared/Components/_App/PageView';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import {
  AUTHORITY2,
  DELIVERY_STATUS,
  DOCUMENT_STATUSES,
  PERMISSIONS_GROUP_NAMES,
  PERMISSIONS_MODULE_NAME,
  PERMISSIONS_NAME,
} from '../../../shared/Constants/Constants';
import { toLocalDate } from '../../../shared/Utils/Helper';
import { useStyle } from './SpDeliveryViewPage.app.styles';

const SpDeliveryViewPage = (props) => {
  const classes = useStyle();
  const account = useAccount();

  const { hasAuth } = useAuthority();
  const isPRO = hasAuth([AUTHORITY2.PRO]);
  const [delivery, setDelivery] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [showDeliverySubmitModal, setShowDeliverySubmitModal] = useState(false);
  const [generateInvoiceInprogress, setGenerateInvoiceInprogress] = useState(false);
  const [generateEwaybilEinvoiceInprogress, setGenerateEwaybillEinvoiceInprogress] =
    useState(false);
  const [statusToIntransitInprogress, setStatusToIntransitInprogress] = useState(false);

  const [error, setError] = useState(null);
  const [{ transError, transLoading, transporterValue }, { dofetchTransporter }] = useTransporter();
  const [{ value }, { doFetchTracking }] = useTracking();
  const history = useHistory();

  const [users, setUsers] = useState({});
  const [displayDisputeModal, setDisplayDisputeModal] = useState(false);
  const [displayApproveModal, setDisplayApproveModal] = useState(false);
  const [isDeliveryDeleteEditModalVisible, setIsDeliveryDeleteEditModalVisible] = useState(false);

  const { id } = props.match.params;
  const myCustomerId = account.user?.profile?.id;

  const mandatoryDocs = {
    PICKUP_WB: 'PICKUP_WB',
    PICKUP_PHOTOS: 'PICKUP_PHOTOS',
    DROP_OFF_WB: 'DROP_OFF_WB',
    DROP_OFF_PHOTOS: 'DROP_OFF_PHOTOS',
  };
  //Once delivery is created max qty is calculated from assigneddeliveries
  const maxQty =
    Object.keys(delivery).length > 0 &&
    (delivery?.assignedToWoItem
      ? delivery?.assignedToWoItem?.qty - delivery?.assignedToWoItem?.fulfilledQty + delivery?.qty
      : delivery?.assignedDeliveries[0]?.assignedToWoItem?.qty -
        delivery?.assignedDeliveries[0]?.assignedToWoItem?.fulfilledQty +
        delivery?.assignedDeliveries[0]?.qty);

  const formik = useFormik({
    initialValues: {
      qty: delivery?.qty,
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: (props) => {
      return Yup.lazy((values) => {
        return Yup.object().shape({
          qty: Yup.number()
            .max(maxQty, 'Quantity should be <= Need of WorkOrder')
            .min(0, 'Quantity should be greater than or equal to Allocated Quantity')
            .required('Enter Qty'),
        });
      });
    },
    onSubmit: (values, actions) => {
      setShowDeliverySubmitModal(true);
    },
  });

  useAsyncEffect(async () => {
    await dofetchTransporter();
  }, []);

  useAsyncEffect(async () => {
    doFetchDelivery();
    doFetchTracking(id);
    try {
      const response = await DeliveryService.getUsersDetails();
      if (response.status === 200) {
        setUsers(response?.data);
      }
    } catch (err) {
      console.log('error', err);
    }
  }, []);

  const doFetchDelivery = async () => {
    try {
      const response = await DeliveryService.fetchDeliveryById(id);
      if (response === 400) {
        if (isPRO) history.push('/deliveries-list');
        if (!isPRO) history.push('/tabs/open-deliveries?tab=true');
      } else setDelivery(response?.data);
      setLoading(false);
    } catch (error) {
      setError(error?.response?.data);
    }
  };

  const doChangeStatusToIntransit = async () => {
    setStatusToIntransitInprogress(true);
    try {
      const response = await DeliveryService.markStatusToIntransit({
        id: delivery?.assignedDeliveries[0]?.id,
        commnet: '',
      });
      await doFetchDelivery();
      setStatusToIntransitInprogress(false);
    } catch (error) {
      setStatusToIntransitInprogress(false);
    }
  };
  /**
   * This api call is used to update delivery
   * @param {*} deliveryUpdatePayload
   * @return {Promise<*>}
   */
  const doUpdateDelivery = async (deliveryUpdatePayload) => {
    try {
      const deliveryRes = await DeliveryService.updateDelivery(deliveryUpdatePayload);
      setUpdateInProgress(false);
      setDelivery(deliveryRes?.data);
      toast.success('Quantity Updated Successfully');
    } catch (error) {
      setUpdateInProgress(false);
    }
  };

  /**
   * This api call is used to update delivery
   * @param {*} deliveryUpdatePayload
   * @return {Promise<*>}
   */
  const doSendSMSToPickUpSupervisor = async () => {
    try {
      await DeliveryService.sendPickUpspSms({ id: id });
      toast.success('SMS sent succesfully');
    } catch (error) {
      //setUpdateInProgress(false);
    }
  };

  /**
   * This api call is used to update delivery
   * @return {Promise<*>}
   */
  const doSendSMSToDropSupervisor = async () => {
    try {
      await DeliveryService.sendDropspSms({ id: id });
      toast.success('SMS sent succesfully');
    } catch (error) {
      // setUpdateInProgress(false);
    }
  };
  const doSendSMSToDriver = async () => {
    try {
      await DeliveryService.doSendSMSToDriver({
        mobileNo: delivery?.deliveryDetails?.driverMobile,
        vehicleNo: delivery?.deliveryDetails?.pickUpVehicleNumber,
        appCode: 'EPR005',
        orderId: delivery.rootDeliveryId,
      });
      toast.success('SMS sent succesfully');
      await doFetchTracking(id);
    } catch (error) {
      //setUpdateInProgress(false);
    }
  };

  /**
   * This api call is used to update delivery
   * @param {*} updateQtyPayload
   * @return {Promise<*>}
   */
  const doQtySubmitForApproval = async (updateQtyPayload) => {
    try {
      setSubmitInProgress(true);
      const response = await DeliveryService.qtySubmitForApproval({
        ...delivery,
        ...updateQtyPayload,
      });
      if (response?.status === 200) {
        setShowDeliverySubmitModal(false);
        doFetchDelivery();
        toast.success('Successfully Submitted For Approval');
      }
      setSubmitInProgress(false);
    } catch (error) {
      setSubmitInProgress(false);
    }
  };

  /**
   * This api call is used to update delivery
   * @return {Promise<*>}
   */
  const doGenerateEwaybillEInvoice = async () => {
    setGenerateEwaybillEinvoiceInprogress(true);
    try {
      await DeliveryService.generateEwaybillEinvoice({
        id: id,
      });
      setGenerateEwaybillEinvoiceInprogress(false);
      doFetchDelivery();
    } catch (error) {
      setGenerateEwaybillEinvoiceInprogress(false);
    }
  };

  /**
   * This api call is used to update delivery
   * @return {Promise<*>}
   */
  const doGenerateInvoice = async () => {
    setGenerateInvoiceInprogress(true);
    try {
      await DeliveryService.generateInvoice({
        id: id,
      });
      setGenerateInvoiceInprogress(false);
      doFetchDelivery();
    } catch (error) {
      setGenerateInvoiceInprogress(false);
    }
  };

  const displayMyself = (userMobile, selectedMobile, selectedName) => {
    return userMobile === selectedMobile ? 'Myself' : selectedName;
  };

  // Once delivery is created workorder id should to be displayed from assignedDeliveries array
  const displayWoId =
    Object.keys(delivery).length > 0 &&
    (delivery?.assignedToWoItem
      ? delivery?.assignedToWoItem?.workOrderId
      : delivery?.assignedDeliveries[0]?.assignedToWoItem?.workOrderId);

  // This variable gives list of pickup weighbridge and vehicle photos which are in approved status

  const isSourceMandatoryDocList =
    (delivery?.deliveryDocuments?.length > 0 &&
      delivery?.deliveryDocuments
        .filter(
          (doc) =>
            (doc.document?.type?.includes(mandatoryDocs.PICKUP_WB) &&
              doc.status === DELIVERY_STATUS.APPROVED.id) ||
            (doc.document?.type?.includes(mandatoryDocs.PICKUP_PHOTOS) &&
              doc.status === DELIVERY_STATUS.APPROVED.id)
        )
        .map((e) => {
          return e.document.type;
        })) ||
    [];

  const isPickupWbPresent = isSourceMandatoryDocList?.includes(mandatoryDocs.PICKUP_WB);
  const isPickupVehicleDocPresent = isSourceMandatoryDocList?.includes(mandatoryDocs.PICKUP_PHOTOS);

  // This variable gives list of Drop weighbridge and vehicle photos which are in approved status

  const isDestinationMandatoryDocList =
    (delivery?.deliveryDocuments?.length > 0 &&
      delivery?.deliveryDocuments
        .filter(
          (doc) =>
            (doc.document?.type?.includes(mandatoryDocs.DROP_OFF_WB) &&
              doc.status === DELIVERY_STATUS.APPROVED.id) ||
            (doc.document?.type?.includes(mandatoryDocs.DROP_OFF_PHOTOS) &&
              doc.status === DELIVERY_STATUS.APPROVED.id)
        )
        .map((e) => {
          return e.document.type;
        })) ||
    [];

  const isDropWbDocPresent = isDestinationMandatoryDocList?.includes(mandatoryDocs.DROP_OFF_WB);

  //Submit button is disabled if atleast one pickup weighbridge or pickup vehicle photos or drop weighbridge or drop vehicle photos are not present

  const isPicupDocPresent = isPickupWbPresent && isPickupVehicleDocPresent;

  const isDropDocPresent = isDropWbDocPresent;

  const disableGenerateEwayInvoice = () => {
    const isInreview = delivery?.deliveryDocuments?.find(
      (doc) => doc.document.type.includes('PICKUP_') && doc.status === DOCUMENT_STATUSES.IN_REVIEW
    );
    if (!isEmpty(isInreview) || !isPickupWbPresent || !isPickupVehicleDocPresent) {
      return true;
    } else return false;
  };
  const pickupDocs = delivery?.deliveryDocuments?.filter((doc) =>
    doc.document?.type?.includes('PICKUP_')
  );
  const isDocsApprovedRejectedView = () => {
    const pickupDocs = delivery?.deliveryDocuments?.filter((doc) =>
      doc.document?.type?.includes('PICKUP_')
    );
    const inReviewDocs = pickupDocs?.find((doc) => doc.status === DOCUMENT_STATUSES.IN_REVIEW);
    if (isEmpty(inReviewDocs)) {
      const rejectedList = pickupDocs?.filter((doc) => doc.status === DOCUMENT_STATUSES.REJECTED);
      if (rejectedList?.length === 0) {
        return (
          <Paper elevation={0} className={classes.approvePaper}>
            <div style={{ display: 'flex' }}>
              <Typography variant="h5" className="m-3">
                YAY! You have approved all the documents for this delivery..!
              </Typography>
              <Avatar className={classes.approveAvtar}>
                <FontAwesomeIcon size="sm" color="#e6fff9" icon={faCheck} />
              </Avatar>
            </div>
          </Paper>
        );
      } else {
        return (
          <Paper elevation={0} className={classes.alertPaper}>
            <div style={{ display: 'flex' }}>
              <Typography variant="h5" className="m-3">
                {pickupDocs?.length - rejectedList?.length} out of {pickupDocs?.length} documents
                are approved !
              </Typography>
              <Avatar className={classes.alertAvtar}>
                <FontAwesomeIcon size="sm" color="#ffc918" icon={faExclamationTriangle} />
              </Avatar>
            </div>
          </Paper>
        );
      }
    }
  };
  const isDocGeneratedSuccessView = () => {
    const pickUpewaybillEinvoice = delivery?.deliveryDocuments?.find(
      (doc) =>
        doc.document.type.includes('PICKUP_INVOICE') ||
        doc.document.type.includes('PICKUP_E_WAY_BILL')
    );
    if (!isEmpty(pickUpewaybillEinvoice)) {
      return true;
    } else {
      return false;
    }
  };
  const genratedEwaybillEinvoice =
    delivery?.assignedDeliveries &&
    delivery?.assignedDeliveries[0]?.deliveryDocuments?.find(
      (doc) =>
        doc.document.type.includes('PICKUP_INVOICE') ||
        doc.document.type.includes('PICKUP_E_WAY_BILL')
    );
  const showIntransitButton = false;
  // delivery?.assignedDeliveries &&
  // delivery?.assignedDeliveries[0]?.status === DELIVERY_STATUS.IN_PROGRESS.id &&
  // !isEmpty(genratedEwaybillEinvoice);

  const showEdit =
    delivery?.assignedDeliveries &&
    (delivery?.assignedDeliveries[0]?.status === DELIVERY_STATUS.IN_PROGRESS.id ||
      delivery?.assignedDeliveries[0]?.status === DELIVERY_STATUS.IN_TRANSIT.id);

  const showSubmitForApproval =
    !isPRO &&
    delivery?.assignedDeliveries &&
    (delivery?.assignedDeliveries[0]?.status === DELIVERY_STATUS.IN_PROGRESS.id ||
      delivery?.assignedDeliveries[0]?.status === DELIVERY_STATUS.IN_TRANSIT.id) &&
    isPicupDocPresent &&
    isDropDocPresent;

  const doApproveDelivery = async () => {
    try {
      const response = await DeliveryService.approveDeliveryByParams({ ids: delivery?.id });
      toast.success('Delivery approved successfully.');
      setDisplayApproveModal(false);
      setDisplayDisputeModal(false);
      doFetchDelivery();
      return response;
    } catch (error) {}
  };

  const isGivenDelivery = () => {
    return delivery.customerId != myCustomerId;
  };

  const showApproveButton =
    [DELIVERY_STATUS.IN_REVIEW.id, DELIVERY_STATUS.DISPUTED.id].indexOf(delivery.status) >= 0 &&
    !isGivenDelivery();

  const showDisputeButton =
    delivery.status == DELIVERY_STATUS.IN_REVIEW.id &&
    delivery?.actionsAllowed?.canDispute &&
    !isGivenDelivery();

  const isQuantityDisabled =
    delivery?.assignedDeliveries &&
    (delivery?.assignedDeliveries[0]?.status === DELIVERY_STATUS.IN_PROGRESS.id ||
      delivery?.assignedDeliveries[0]?.status === DELIVERY_STATUS.IN_TRANSIT.id);

  const isDocumentApproved = delivery?.deliveryDocuments?.some(
    (delDoc) => delDoc?.assignedStatus === 'APPROVED'
  );
  const isDeleteOptionVisible = isEmpty(delivery?.assignedDeliveries)
    ? false
    : delivery?.assignedDeliveries[0]?.status?.toUpperCase()?.trim() === 'APPROVED' &&
      isDocumentApproved;

  return (
    <Box className={classes.deliveryMain}>
      <AppHeader
        titleRender={(route) => (
          <div className={classes.headerText}>
            <Typography variant={'subtitle2'} noWrap>
              #{id}
            </Typography>
            <span className={'text-small text-muted font-regular text-uppercase'}>
              Created on {toLocalDate(delivery?.createdOn, 'DD-MMM-YYYY hh:mm a')}
            </span>
          </div>
        )}
        rightRender={(route) => (
          <>
            <div>
              <FontAwesomeIcon
                size="sm"
                icon={faEllipsisV}
                onClick={() => !isPRO && setIsDeliveryDeleteEditModalVisible(true)}
                style={{ fontWeight: 'bold', color: '#2E3748', fontSize: '18px' }}
              />
            </div>
          </>
        )}
      />
      <PageView loading={isLoading}>
        {isPRO && pickupDocs?.length > 0 && !isDocGeneratedSuccessView() && (
          <>{isDocsApprovedRejectedView()}</>
        )}
        <Paper elevation={0} className="px-4 pt-3 mb-4">
          <Grid container spacing={2}>
            <Grid item xs={showIntransitButton ? 4 : 6}>
              <FormLabel label={'Work Order'}></FormLabel>
              <Typography variant={'subtitle2'}>{displayWoId || 'NA'}</Typography>
            </Grid>
            <Grid item xs={showIntransitButton ? 4 : 6}>
              <FormLabel label={'Item'}></FormLabel>
              <Typography variant={'subtitle2'}>{delivery?.item}</Typography>
            </Grid>

            {/* {showIntransitButton && (
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => doChangeStatusToIntransit()}
                  disabled={statusToIntransitInprogress}
                  style={{ height: '36px', fontWeight: 'bold' }}>
                  In transit
                </Button>
              </Grid>
            )} */}

            {!isPRO && (
              <Grid item xs={12} className="mb-2">
                <FormField
                  disabled={true}
                  FormControlClassName="mb-0"
                  label="Final Quantity (In KG)"
                  type="number"
                  value={formik.values.qty}
                  error={formik.errors?.qty}
                  isValid={!formik.errors?.qty && formik.dirty}
                  onChange={formik.handleChange('qty')}
                  onBlur={formik.handleBlur('qty')}
                  touched={formik.touched?.qty}
                />
                <Typography color="error" variant="body1">
                  {formik.errors.qty}
                </Typography>
              </Grid>
            )}
          </Grid>
          <List>
            <FormLabel label={'Status'}></FormLabel>
            <ListItem className="p-0">
              <ListItemAvatar>
                <TruckIcon />
              </ListItemAvatar>
              <ListItemText
                primary={
                  !isPRO && !isEmpty(delivery) && delivery?.assignedDeliveries[0]
                    ? DELIVERY_STATUS[delivery?.assignedDeliveries[0]?.status]?.displayText
                    : DELIVERY_STATUS[delivery?.status]?.displayText
                }
                secondary={toLocalDate(delivery?.modifiedOn)}
              />
            </ListItem>
          </List>
        </Paper>

        {isPRO &&
          delivery?.deliveryDetails?.pickUpInvoiceNumber !== null &&
          !isDocGeneratedSuccessView() && (
            <DeliveryInvoiceDocGeneratedSuccessView delivery={delivery} />
          )}
        {isPRO &&
          delivery?.deliveryDetails?.pickUpInvoiceNumber !== null &&
          isDocGeneratedSuccessView() === true && (
            <Grid container>
              <DeliveryDocGeneratedSuccessView delivery={delivery} />
            </Grid>
          )}
        <Grid container>
          <DeliveryDocument
            doUpdateDelivery={doUpdateDelivery}
            delivery={delivery}
            doFetchDelivery={doFetchDelivery}
            isPRO={isPRO}
            maxQty={maxQty}
            usersList={users}
          />
        </Grid>
        <DeliveryLocationsDetail data={delivery?.deliveryDetails} isEditable={false} />
        {!isPRO && (
          <DeliverySupervisorDetails
            data={delivery?.deliveryDetails}
            isEditable={false}
            user={account.user}
            displayMyself={displayMyself}
            isPRO={isPRO}
            doSendSMSToPickUpSupervisor={doSendSMSToPickUpSupervisor}
            doSendSMSToDropSupervisor={doSendSMSToDropSupervisor}
            assignedDeliveryStatus={
              delivery?.assignedDeliveries && delivery?.assignedDeliveries[0]?.status
            }
            sendSMS={true}
          />
        )}
        {isPRO && <SupervisorPickupDetails data={delivery} />}
        <DeliveryTranporterDetails
          data={delivery?.deliveryDetails}
          isEditable={false}
          transporterValue={transporterValue}
          isPRO={isPRO}
          value={value}
          doSendSMSToDriver={doSendSMSToDriver}
          sendSMS={true}
        />
        {showSubmitForApproval && (
          <LoadingButton
            className={classes.submitBottom}
            loading={updateInProgress}
            loadingText={'Loading..'}
            disabled={submitInProgress || !formik.isValid}
            onClick={() => formik.handleSubmit()}>
            Submit For Approval
          </LoadingButton>
        )}
        {isPRO &&
          delivery?.deliveryDetails?.pickUpInvoiceNumber !== null &&
          delivery?.status === DELIVERY_STATUS.IN_PROGRESS.id &&
          !isDocGeneratedSuccessView() && (
            <LoadingButton
              className={classes.submitBottom}
              loading={updateInProgress}
              loadingText={'Generating..'}
              disabled={disableGenerateEwayInvoice() || generateEwaybilEinvoiceInprogress}
              onClick={() => doGenerateEwaybillEInvoice()}>
              Generate E-way bill & E-Invoice
            </LoadingButton>
          )}
        {isPRO &&
          delivery?.deliveryDetails?.pickUpInvoiceNumber === null &&
          delivery?.status === DELIVERY_STATUS.IN_PROGRESS.id && (
            <LoadingButton
              className={classes.submitBottom}
              // loading={generateInvoiceInprogress}
              loadingText={'Generating..'}
              disabled={disableGenerateEwayInvoice() || generateInvoiceInprogress}
              onClick={() => doGenerateInvoice()}>
              Generate Invoice
            </LoadingButton>
          )}
        {isPRO && (showDisputeButton || showApproveButton) && (
          <>
            <div className={classes.proButtonContainer}>
              {showDisputeButton && (
                <Button
                  variant="contained"
                  className={classes.proDisputeButton}
                  onClick={() => {
                    setDisplayDisputeModal(true);
                  }}>
                  Dispute
                </Button>
              )}
              {showApproveButton && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.proApproveButton}
                  onClick={() => setDisplayApproveModal(true)}>
                  Approve
                </Button>
              )}
            </div>
          </>
        )}
        <DeliverySubmitForApprovalModal
          open={showDeliverySubmitModal}
          onClose={() => setShowDeliverySubmitModal(false)}
          onSelect={() => doQtySubmitForApproval({ qty: formik.values.qty })}
          qty={formik.values.qty}
          submitInProgress={submitInProgress}
        />
        <DocumentHistoryContainer
          delivery={delivery}
          displayDisputeModal={displayDisputeModal}
          setDisplayDisputeModal={setDisplayDisputeModal}
          // TODO : need to remove this props while refactoring document module
          canDispute={delivery?.actionsAllowed?.canDispute}
          isMobile="isMobile"
          doFetchDelivery={doFetchDelivery}
          groupName={PERMISSIONS_GROUP_NAMES.DELIVERY}
          moduleName={PERMISSIONS_MODULE_NAME.DELIVERY}
          permissionName={PERMISSIONS_NAME.DISPUTE_DELIVERY}
        />
        {isDeliveryDeleteEditModalVisible && !isPRO && showEdit && (
          <DeliveryEditAndDeleteOptionModal
            setIsDeliveryDeleteEditModalVisible={setIsDeliveryDeleteEditModalVisible}
            isDeliveryDeleteEditModalVisible={isDeliveryDeleteEditModalVisible}
            id={id}
            showEdit={showEdit}
            DeliveryService={DeliveryService}
            isDeleteOptionVisible={false}
          />
        )}
        <ConfirmDeliveryModal
          displayApproveModal={displayApproveModal}
          setDisplayApproveModal={setDisplayApproveModal}
          approveDelivery={doApproveDelivery}
          delivery={delivery}
          isMobile="isMobile"
        />
      </PageView>
    </Box>
  );
};

export default SpDeliveryViewPage;
