import * as Yup from 'yup';

export const RECYCLER_PORTAL_APP_CODE = 'RPL009';

export const FULFILLMENT_CYCLES = [
  {
    id: 'MONTHLY',
    label: 'Monthly',
    value: 'MONTHLY',
  },
  {
    id: 'QUARTERLY',
    label: 'Quarterly',
    value: 'QUARTERLY',
  },
  {
    id: 'SEMIANNUALLY',
    label: 'Semi Annually',
    value: 'SEMIANNUALLY',
  },
  {
    id: 'ANNUALLY',
    label: 'Annually',
    value: 'ANNUALLY',
  },
];

export const WO_TYPES = [
  {
    id: 'EPR_LOOP_VENDORS',
    label: 'EPR loop vendors',
    value: false,
  },
  {
    id: 'MARKETPLACE_VENDORS',
    label: 'Marketplace vendors',
    value: true,
  },
];

export const filtersMapper = {
  deliveryToDate: 'To Date',
  deliveryFromDate: 'From Date',
  OrderDateTo: 'Order To Date',
  OrderDateFrom: 'Order From Date',
  Items: 'Items',
  Districts: 'Districts',
  states: 'States',
  status: 'Status',

  docTypes: 'Doc Type',
  docStatus: 'Doc Status',
  filterCriteria: 'Criteria',
  FulfillmentCycle: 'Fulfillment Cycle',
  WorkOrderInOutStatus: 'WO Type',
};

export const PAGE_CONFIG = {
  size: 20,
};

export const EXECUTION_MODE = [
  {
    label: 'Service Provider',
    value: 'SERVICE_PROVIDER',
  },
  {
    label: 'Internal',
    value: 'INTERNAL',
  },
];
export const pageOptions = [
  {
    id: 1,
    label: 20,
    value: 20,
  },
  {
    id: 2,
    label: 40,
    value: 40,
  },
  {
    id: 3,
    label: 50,
    value: 50,
  },
  {
    id: 4,
    label: 100,
    value: 100,
  },
];

export const pageOptionsAudit = [
  {
    id: 1,
    label: 20,
    value: 20,
  },
  {
    id: 2,
    label: 50,
    value: 50,
  },
  {
    id: 3,
    label: 100,
    value: 100,
  },
];

export const WORK_ORDER_STATUS = [
  {
    label: 'In Progress',
    id: 'IN_PROGRESS',
    color: '#7297ff',
  },
  {
    label: 'Draft',
    id: 'DRAFT',
  },
  {
    label: 'Closed',
    id: 'CLOSED',
  },
  {
    label: 'Completed',
    id: 'COMPLETED',
  },
  {
    label: 'In Complete',
    id: 'IN_COMPLETE',
  },
];

export const WO_STATUSES = [
  {
    id: 'APPROVED',
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    id: 'IN_PROGRESS',
    label: 'In Progress',
    value: 'IN_PROGRESS',
  },
  {
    id: 'DRAFT',
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    id: 'IN_REVIEW',
    label: 'In Review',
    value: 'IN_REVIEW',
  },
  {
    id: 'REJECTED',
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    id: 'CLOSED',
    label: 'Closed',
    value: 'CLOSED',
  },
  {
    id: 'COMPLETED',
    label: 'Completed',
    value: 'COMPLETED',
  },
  {
    id: 'COMPLETEDCLOSED',
    label: 'Completed & Closed',
    value: 'COMPLETEDCLOSED',
  },
];

export const WORK_ORDER_FULLFILMENT_STATUS = [
  {
    label: 'Pending',
    id: 'PENDING',
  },

  {
    label: 'Delivery Initiated',
    id: 'DELIVERY_INITIATED',
  },
];
// CREATED, PICKED_UP, DROPPED_OFF, APPROVED, DISPUTE,RESOLVED
export const DELIVERY_STATUS = {
  CREATED: { id: 'CREATED', displayText: 'Created', colors: 'green' },
  PICKED_UP: { id: 'PICKED_UP', displayText: 'Picked up', colors: 'green' },
  DRAFT: { id: 'DRAFT', displayText: 'Draft', colors: 'green' },
  DROPPED_OFF: {
    id: 'DROPPED_OFF',
    displayText: 'Dropped off',
    colors: 'green',
  },
  APPROVED: { id: 'APPROVED', displayText: 'Approved', colors: 'green' },
  UNALLOCATED: {
    id: 'UNALLOCATED',
    displayText: 'UnAllocated',
    colors: 'green',
  },
  PARTIALLY_ALLOCATED: {
    id: 'PARTIALLY_ALLOCATED',
    displayText: 'Partially Allocated',
    colors: 'green',
  },
  ALLOCATED: { id: 'ALLOCATED', displayText: 'Allocated', colors: 'green' },
  DISPUTED: { id: 'DISPUTED', displayText: 'Disputed', colors: 'green' },
  IN_REVIEW: { id: 'IN_REVIEW', displayText: 'In Review', colors: 'green' },
  IN_PROGRESS: { id: 'IN_PROGRESS', displayText: 'In Progress', colors: 'green' },
  IN_TRANSIT: { id: 'IN_TRANSIT', displayText: 'In Transit', colors: 'green' },
  NEW: { id: 'NEW', displayText: 'New', colors: 'green' },
};
export const DELIVERY_STATUS_BADGE = {
  CREATED: { id: 'CREATED', displayText: 'Created', colors: 'green' },
  PICKED_UP: { id: 'PICKED_UP', displayText: 'Picked up', colors: 'green' },
  DRAFT: { id: 'DRAFT', displayText: 'Draft', colors: 'green' },
  DROPPED_OFF: {
    id: 'DROPPED_OFF',
    displayText: 'Dropped off',
    colors: 'green',
  },
  APPROVED: {
    id: 'APPROVED',
    displayText: 'Approved',
    colors: 'green',
    bg: '#ECFDF3',
    borderColor: '#ABEFC6',
    textColor: '#067647',
  },
  UNALLOCATED: {
    id: 'UNALLOCATED',
    displayText: 'UnAllocated',
    colors: 'green',
  },
  PARTIALLY_ALLOCATED: {
    id: 'PARTIALLY_ALLOCATED',
    displayText: 'Partially Allocated',
    colors: 'green',
  },
  ALLOCATED: { id: 'ALLOCATED', displayText: 'Allocated', colors: 'green' },
  DISPUTED: { id: 'DISPUTED', displayText: 'Disputed', colors: 'green' },
  IN_REVIEW: {
    id: 'IN_REVIEW',
    displayText: 'In Review',
    colors: 'orange',
    bg: '#FFFAEB',
    borderColor: '#FEDF89',
    textColor: '#B54708',
  },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    displayText: 'In Progress',
    colors: 'green',
    bg: '#ECFDF3',
    borderColor: '#ABEFC6',
    textColor: '#067647',
  },
  IN_TRANSIT: { id: 'IN_TRANSIT', displayText: 'In Transit', colors: 'green' },
  NEW: { id: 'NEW', displayText: 'New', colors: 'green' },
};

// new delivery Statuses
export const NEW_DELIVERY_STATUS = {
  CREATED: { id: 'CREATED', displayText: 'Created', colors: 'green' },
  PICKED_UP: { id: 'PICKED_UP', displayText: 'Picked up', colors: 'green' },
  DROPPED_OFF: {
    id: 'DROPPED_OFF',
    displayText: 'Dropped off',
    colors: 'green',
  },
  APPROVED: { id: 'APPROVED', displayText: 'Approved', colors: 'green' },
  AUTO_ALLOCATION: {
    id: 'AUTO_ALLOCATION',
    displayText: 'Auto Allocation',
    colors: 'green',
  },
  PARTIALLY_ALLOCATED: {
    id: 'PARTIALLY_ALLOCATED',
    displayText: 'Partially Allocated',
    colors: 'green',
  },
  CERTIFICATE_GENERATION: {
    id: 'CERTIFICATE_GENERATION',
    displayText: 'Certificate Generation',
    colors: 'green',
  },
  KAM_REVIEW: {
    id: 'KAM_REVIEW',
    displayText: 'Kam Review',
    colors: 'green',
  },
  KAM_APPROVED: {
    id: 'KAM_APPROVED',
    displayText: 'Kam / Brand Approved',
    colors: 'green',
  },
  DISPUTED: { id: 'DISPUTED', displayText: 'Disputed', colors: 'green' },
  IN_REVIEW: { id: 'IN_REVIEW', displayText: 'In Review', colors: 'green' },
  IN_PROGRESS: { id: 'IN_PROGRESS', displayText: 'In Progress', colors: 'green' },
  IN_TRANSIT: { id: 'IN_TRANSIT', displayText: 'In Transit', colors: 'green' },
  NEW: { id: 'NEW', displayText: 'New', colors: 'green' },
};

// IN_PROGRESS, AT_RISK, UNPLANNED, MET, APPROVED, DRAFT, UNALLOCATED

export const WORK_ORDER_DISPLAY_STATUS = {
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    displayText: 'In progress',
    bg: '#EFF8FF',
    borderColor: '#B2DDFF',
    textColor: '#175CD3',
  },
  COMPLETED: {
    id: 'COMPLETED',
    displayText: 'Completed',
    bg: '#ECFDF3',
    borderColor: '#ABEFC6',
    textColor: '#067647',
  },
  REJECTED: {
    id: 'REJECTED',
    displayText: 'Rejected',
    bg: '#FEF3F2',
    borderColor: '#FECDCA',
    textColor: '#B42318',
  },
  CLOSED: {
    id: 'CLOSED',
    displayText: 'Closed',
    colors: 'red',
    bg: '#FEF3F2',
    borderColor: '#FECDCA',
    textColor: '#B42318',
  },
  IN_REVIEW: {
    id: 'IN_REVIEW',
    displayText: 'In Review',
    bg: '#FFFAEB',
    borderColor: '#FEDF89',
    textColor: '#B54708',
  },
  IN_COMPLETE: {
    id: 'IN_COMPLETE',
    displayText: 'In Complete',
    bg: '#FFFAEB',
    borderColor: '#FEDF89',
    textColor: '#B54708',
  },
  AT_RISK: { id: 'AT_RISK', displayText: 'At risk', colors: 'green' },
  UNPLANNED: { id: 'UNPLANNED', displayText: 'Unplanned', colors: 'green' },
  MET: { id: 'MET', displayText: 'Met', colors: 'green' },
  APPROVED: {
    id: 'APPROVED',
    displayText: 'Approved',
    bg: '#ECFDF3',
    borderColor: '#ABEFC6',
    textColor: '#067647',
  },
  DRAFT: {
    id: 'DRAFT',
    displayText: 'Draft',
    bg: '#F9FAFB',
    borderColor: '#EAECF0',
    textColor: '#344054',
  },
  UNALLOCATED: {
    id: 'UNALLOCATED',
    displayText: 'Unallocated',
    colors: 'green',
  },
};

//APPROVED
export const INVOICE_DISPLAY_STATUS = {
  APPROVED: {
    id: 'APPROVED',
    displayText: 'Approved',
  },
  PARTIALLYPAID: {
    id: 'PARTIALLY_PAID',
    displayText: 'Partially Paid',
  },
  PAID: {
    id: 'PAID',
    displayText: 'Paid',
  },
  REJECTED: {
    id: 'REJECTED',
    displayText: 'Rejected',
  },
  OUTSTANDING: {
    id: 'OUTSTANDING',
    displayText: 'Outstanding',
  },
  DRAFT: {
    id: 'DRAFT',
    displayText: 'Draft',
  },
  ACCOUNTED: {
    id: 'ACCOUNTED',
    displayText: 'Accounted',
  },
  IN_REVIEW: {
    id: 'IN_REVIEW',
    displayText: 'In Review',
  },
  PENDING: {
    id: 'PENDING',
    displayText: 'Pending',
  },
};

// export const DELIVERY_STATUS_LIST = [
//   {
//     label: 'CREATED',
//     id: 'MET',
//     color: '#59e1ca'
//   },
//   {
//     label: 'APPROVED',
//     id: 'MET',
//     color: '#59e1ca'
//   }

// ];

export const WORK_ORDER_TYPE = [
  {
    label: 'Target',
    value: 'TARGET',
  },
  {
    label: 'Execution',
    value: 'EXECUTION',
  },
];

export const DOCUMENT_STATUSES = {
  IN_REVIEW: 'IN_REVIEW',
  REJECTED: 'REJECTED',
  NO_DOC: 'NO_DOC',
  APPROVED: 'APPROVED',
};

export const DOCUMENT_STATUS_LIST = [
  {
    label: 'All',
    id: 'ALL',
    color: null,
  },
  {
    label: 'In Review',
    id: 'IN_REVIEW',
    color: '#7A96F8',
  },
  {
    label: 'Rejected',
    id: 'REJECTED',
    color: '#ff7272',
  },
  {
    label: 'No doc',
    id: 'NO_DOC',
    color: '#ffcf8f',
  },
  {
    label: 'Pending',
    id: 'PENDING',
    color: '#ffcf8f',
  },
  {
    label: 'Approved',
    id: 'APPROVED',
    color: '#80DECA',
  },
];

export const SOUCRE_LOCATION_TYPE = [
  {
    label: 'Post-Consumer',
    id: 'Post-Consumer',
  },
  {
    label: 'Industrial',
    id: 'Industrial',
  },
  {
    label: 'ULBs',
    id: 'ULBs',
  },
  {
    label: 'Talukas',
    id: 'Talukas',
  },
  {
    label: 'Waste Pickers',
    id: 'Waste Pickers',
  },
];

export const USER_ROLES = [
  {
    id: 'Admin',
    role: 'Admin',
  },
  {
    id: 'Operations',
    role: 'Operations',
  },
  {
    id: 'Auditor',
    role: 'Auditor',
  },
];

export const DELIVERY_STATUS_LIST_SP = [
  {
    label: 'In Progress',
    id: 'IN_PROGRESS',
    color: '#7297ff',
  },
  {
    label: 'In Transit',
    id: 'IN_TRANSIT',
    color: '#7297ff',
  },
  {
    label: 'In Review',
    id: 'IN_REVIEW',
    color: '#7297ff',
  },
  {
    label: 'Disputed',
    id: 'DISPUTED',
    color: '#ff7272',
  },
  {
    label: 'Approved',
    id: 'APPROVED',
    color: '#59e1ca',
  },
  {
    label: 'Allocated',
    id: 'ALLOCATED',
    color: '#7297ff',
  },
  {
    label: 'UnAllocated',
    id: 'UNALLOCATED',
    color: '#7297ff',
  },
  {
    label: 'Partially Allocated',
    id: 'PARTIALLY_ALLOCATED',
    color: '#7297ff',
  },
];

export const DELIVERY_STATUS_LIST_PRO = [
  {
    label: 'In Progress',
    id: 'IN_PROGRESS',
    color: '#7297ff',
  },
  {
    label: 'In Transit',
    id: 'IN_TRANSIT',
    color: '#7297ff',
  },
  {
    label: 'In Review',
    id: 'IN_REVIEW',
    color: '#7297ff',
  },
  {
    label: 'Disputed',
    id: 'DISPUTED',
    color: '#ff7272',
  },
  {
    label: 'Approved',
    id: 'APPROVED',
    color: '#59e1ca',
  },
  {
    label: 'Allocated',
    id: 'ALLOCATED',
    color: '#7297ff',
  },
  {
    label: 'UnAllocated',
    id: 'UNALLOCATED',
    color: '#7297ff',
  },
  {
    label: 'Partially Allocated',
    id: 'PARTIALLY_ALLOCATED',
    color: '#7297ff',
  },
];

export const BRAND_DELIVERY_STATUS_LIST_PRO = [
  {
    label: 'In Progress',
    id: 'IN_PROGRESS',
    color: '#7297ff',
  },
  {
    label: 'In Review',
    id: 'IN_REVIEW',
    color: '#7297ff',
  },
  {
    label: 'Approved',
    id: 'APPROVED',
    color: '#59e1ca',
  },
];

export const VENDOR_DELIVERY_STATUS_LIST_PRO = [
  {
    label: 'In Progress',
    id: 'IN_PROGRESS',
    color: '#7297ff',
  },
  {
    label: 'In Review',
    id: 'IN_REVIEW',
    color: '#7297ff',
  },
  {
    label: 'Disputed',
    id: 'DISPUTED',
    color: '#ff7272',
  },
  {
    label: 'Allocated',
    id: 'ALLOCATED',
    color: '#7297ff',
  },
  {
    label: 'UnAllocated',
    id: 'UNALLOCATED',
    color: '#7297ff',
  },
  {
    label: 'Partially Allocated',
    id: 'PARTIALLY_ALLOCATED',
    color: '#7297ff',
  },
];

export const DELIVERY_STATUS_LIST_BRAND = [
  {
    label: 'In Review',
    id: 'IN_REVIEW',
    color: '#7297ff',
  },
  {
    label: 'Disputed',
    id: 'DISPUTED',
    color: '#ff7272',
  },
  {
    label: 'Approved',
    id: 'APPROVED',
    color: '#59e1ca',
  },
];

export const MARKETPLACE_MONTH_CARDS_NAME = [
  {
    id: 3,
    label: 'Apr',
    value: 3,
  },
  {
    id: 4,
    label: 'May',
    value: 4,
  },
  {
    id: 5,
    label: 'Jun',
    value: 5,
  },
  {
    id: 6,
    label: 'Jul',
    value: 6,
  },
  {
    id: 7,
    label: 'Aug',
    value: 7,
  },
  {
    id: 8,
    label: 'Sep',
    value: 8,
  },
  {
    id: 9,
    label: 'Oct',
    value: 9,
  },
  {
    id: 10,
    label: 'Nov',
    value: 10,
  },
  {
    id: 11,
    label: 'Dec',
    value: 11,
  },
  {
    id: 0,
    label: 'Jan',
    value: 0,
  },
  {
    id: 1,
    label: 'Feb',
    value: 1,
  },
  {
    id: 2,
    label: 'Mar',
    value: 2,
  },
];

export const RECYL_ACKNOWLEDGMENT_DOCTYPE_OPTIONS = [
  {
    id: 1,
    label: 'Coprocessing',
    type: 'DROP_CO_PROCESSING_CERTIFICATE',
    name: 'Coprocessing Certificate',
    options: [
      {
        id: 'Coprocess1',
        label: 'AVAILABLE',
        value: 'Coprocessing Availaible',
        certificateValue: true,
        certificateId: 1,
      },
      {
        id: 'Coprocess2',
        label: 'NOT AVAILABLE',
        value: 'Coprocessing Not Availaible',
        certificateValue: false,
        certificateId: 1,
      },
      {
        id: 'Coprocess3',
        label: 'OUTDATED',
        value: 'Coprocessing Outdated',
        certificateValue: undefined,
        certificateId: 1,
      },

      {
        id: 'Coprocess4',
        label: 'IN_REVIEW',
        value: 'Coprocessing In_review',
        certificateValue: true,
        certificateId: 1,
      },

      {
        id: 'Coprocess5',
        label: 'APPROVED',
        value: 'Coprocessing Approved',
        certificateValue: false,
        certificateId: 1,
      },
    ],
  },
  {
    id: 2,
    label: 'Recycling',
    type: 'DROP_CERTIFICATE_OF_RECYCLING',
    name: 'Recycling Certificate',
    options: [
      {
        id: 'Recycle1',
        label: 'AVAILABLE',
        value: 'Recycling Availaible',
        certificateValue: true,
        certificateId: 2,
      },
      {
        id: 'Recycle2',
        label: 'NOT AVAILABLE',
        value: 'Recycling Not Availaible',
        certificateValue: false,
        certificateId: 2,
      },
      {
        id: 'Recycle3',
        label: 'OUTDATED',
        value: 'Recycling Outdated',
        certificateValue: undefined,
        certificateId: 2,
      },

      {
        id: 'Recycle4',
        label: 'IN_REVIEW',
        value: 'Recycling In_review',
        certificateValue: true,
        certificateId: 2,
      },

      {
        id: 'Recycle5',
        label: 'APPROVED',
        value: 'Recycling Approved',
        certificateValue: false,
        certificateId: 2,
      },
    ],
  },
  {
    id: 3,
    label: 'CPCB',
    type: 'DROP_CERTIFICATE_OF_CPCB',
    name: 'CPCB Certificate',
    options: [
      {
        id: 'CPCB1',
        label: 'AVAILABLE',
        value: 'CPCB Availaible',
        certificateValue: true,
        certificateId: 3,
      },
      {
        id: 'CPCB2',
        label: 'NOT AVAILABLE',
        value: 'CPCB Not Availaible',
        certificateValue: false,
        certificateId: 3,
      },
      {
        id: 'CPCB3',
        label: 'OUTDATED',
        value: 'CPCB Outdated',
        certificateValue: undefined,
        certificateId: 3,
      },

      {
        id: 'CPCB4',
        label: 'IN_REVIEW',
        value: 'CPCB In_review',
        certificateValue: true,
        certificateId: 3,
      },

      {
        id: 'CPCB5',
        label: 'APPROVED',
        value: 'CPCB Approved',
        certificateValue: false,
        certificateId: 3,
      },
    ],
  },
];

export const PARMS_SPECIFIEDVALUES_BY_ID = {
  Coprocess1: true,
  Coprocess2: false,
  Coprocess3: undefined,
  Coprocess4: true,
  Coprocess5: false,
  Recycle1: true,
  Recycle2: false,
  Recycle3: undefined,
  Recycle4: true,
  Recycle5: false,
  CPCB1: true,
  CPCB2: false,
  CPCB3: undefined,
  CPCB4: true,
  CPCB5: false,
};

export const DELIVERIES_TYPE_OPTIONS = [
  {
    label: 'Marketplace',
    id: 'true',
    color: '#7297ff',
    filterKey: 'isMarketplaceOrder.equals',
  },
  {
    label: 'Recycler Portal',
    id: 'RPL009',
    color: '#7297ff',
    filterKey: 'appCode.equals',
  },
  {
    label: 'All',
    id: 'false',
    color: '#ff7272',
    filterKey: 'isMarketplaceOrder.equals',
  },
];

export const ASSIGNED_DELIVERY_STATUS_LIST = [
  // {
  //   label: 'Created',
  //   id: 'ASSIGNED_CREATED',
  //   color: '#7297ff'
  // },
  {
    label: 'In Review',
    id: 'ASSIGNED_IN_REVIEW',
    color: '#7297ff',
  },
  {
    label: 'Disputed',
    id: 'ASSIGNED_DISPUTED',
    color: '#ff7272',
  },
  {
    label: 'Approved',
    id: 'ASSIGNED_APPROVED',
    color: '#59e1ca',
  },
];

export const WORK_ORDER_STATUS_LIST = [
  {
    label: 'MET',
    id: 'MET',
    color: '#59e1ca',
  },
  {
    label: 'Pending',
    id: 'PENDING',
    color: '#ff7272',
  },
  {
    label: 'Delivery Initiated',
    id: 'DELIVERY_INITIATED',
    color: '#72ddff',
  },
];

export const TARGET_STATUS_LIST = [
  {
    label: 'MET',
    id: 'MET',
    color: '#59e1ca',
  },
  {
    label: 'UNALLOCATED',
    id: 'UNALLOCATED',
    color: '#baf9ef',
  },
  {
    label: 'WORK IN PROGRESS',
    id: 'WORK_IN_PROGRESS',
    color: '#7297ff',
  },
  {
    label: 'AT RISK',
    id: 'AT_RISK',
    color: '#ffcf8f',
  },
  {
    label: 'NOT PLANNED',
    id: 'NOT_PLANNED',
    color: '#ff7272',
  },
  {
    label: 'UPCOMING UNPLANNED TARGETS',
    id: 'UPCOMING_UNPLANNED_TARGETS',
    color: '#72ddff',
  },
];

export const UNIT_TYPES = {
  KG: { displayText: 'Kg' },
  TONNES: { displayText: 'T' },
  QTY: { displayText: 'Kg' },
};

export const LOCATION_TYPE_LIST = [
  {
    label: 'Material Recovery Facility',
    id: 'Material Recovery Facility',
    value: 'Material Recovery Facility',
  },
  {
    label: 'Aggregator',
    id: 'Aggregator',
    value: 'Aggregator',
  },
];

export const UPDATED_LOCATION_TYPE_LIST = [
  {
    label: 'Material Recovery Facility',
    id: 'Material Recovery Facility',
    value: 'Material Recovery Facility',
  },
  {
    label: 'Aggregator',
    id: 'Aggregator',
    value: 'Aggregator',
  },
];

export const LOCATION_TYPE_LIST_NON_RECYCLABLE_PLASTIC = [
  {
    label: 'Material Recovery Facility',
    id: 'Material Recovery Facility',
    value: 'Material Recovery Facility',
  },
];

export const Permission = {
  CLIENT_WORK_ORDERS: 'CLIENT_WORK_ORDERS',
  ASSIGN_DELIVERY_TO_INCOMING_WORK_ORDERS: 'ASSIGN_DELIVERY_TO_INCOMING_WORK_ORDERS',
  UPLOAD_DOCUMENTS_AGAINST_DELIVERY: 'UPLOAD_DOCUMENTS_AGAINST_DELIVERY',
  VIEW_DELIVERY: 'VIEW_DELIVERY',
  EXECUTION: 'EXECUTION',
  CREATE_DELIVERY: 'CREATE_DELIVERY',
  DISPUTE_DELIVERY: 'DISPUTE_DELIVERY',
  PROFILE: 'PROFILE',
  APPROVE_DELIVERY: 'APPROVE_DELIVERY',
  ROLES: 'ROLES',
  DASHBOARD: 'DASHBOARD',
  APPROVE_DISPUTE_DOCUMENTS: 'APPROVE/DISPUTE_DOCUMENTS',
  TARGETS: 'TARGETS',
  SERVICE_PROVIDERS: 'SERVICE_PROVIDERS',
  WORK_ORDERS: 'WORK_ORDERS',
  USERS: 'USERS',
  CLIENTS: 'CLIENTS',
  REPORT: 'REPORT',
  FINANCE: 'FINANCE',
  SUPERUSER: 'SUPERUSER',
};

export const AUTHORITY = {
  USER: 'ROLE_USER',
  SERVICE_PROVIDER: 'ROLE_SERVICE_PROVIDER',
  BRAND: 'ROLE_BRAND',
  PRO: 'ROLE_PRO',
  ADMIN: 'ROLE_EPR_ADMIN',
  SUPERUSER: 'ROLE_SUPERUSER',
};

export const AUTHORITY2 = {
  USER: 'ROLE_EPR_USER',
  SERVICE_PROVIDER: 'ROLE_EPR_SERVICE_PROVIDER',
  BRAND: 'ROLE_EPR_BRAND',
  PRO: 'ROLE_EPR_PRO',
  ADMIN: 'ROLE_EPR_PRO_ADMINSTRATOR',
  SUPERUSER: 'ROLE_EPR_PRO_ADMINSTRATOR',
  LEGAL: 'ROLE_EPR_PRO_BRAND_WO_VERIFIER',
};

export const FCM_ERROR_CODE = {
  PERMISSION_BLOCKED: 'messaging/permission-blocked',
};

export const eventIconMap = {
  DOCUMENT: 'fa-file-text',
  DELIVERY: 'fa-truck fa-flip-horizontal',
  WORK_ORDER: 'fa-cube',
  TARGET: 'fa-dot-circle-o',
  ROLE: 'fa-shield',
  USER: 'fa-user',
  BUSINESS_PROFILE: 'fa-briefcase',
  USER_PROFILE: 'fa-user',
  SERVICE_PROVIDER: 'fa-user',
};

export const ENTITY = {
  DOCUMENT: 'DOCUMENT',
  DELIVERY: 'DELIVERY',
  WORK_ORDER: 'WORK_ORDER',
  TARGET: 'TARGET',
  ROLE: 'ROLE',
  USER: 'USER',
  REPORT: 'REPORT',
  BUSINESS_PROFILE: 'BUSINESS_PROFILE',
  USER_PROFILE: 'USER_PROFILE',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
};

export const EX_SUMMARY_VIEW = {
  FY: 'FY',
  TILL_MONTH: 'TILL_MONTH',
};

export const APP_EVENTS = {
  topic: {
    DELIVERY: 'DELIVERY',
    SERVER_ERROR: 'SERVER_ERROR',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
    ULB: 'ULB',
  },
  event: {
    CHANGE_DELIVERY: 'CHANGE_DELIVERY',
    SERVER_DOWN_503: 'SERVER_DOWN_503',
    SHOW_PRIVACY_MODAL: 'SHOW_PRIVACY_MODAL',
    LOAD_ULB: 'LOAD_ULB',
  },
};

export const INVOICE_STATUS_LIST_SP = [
  {
    label: 'Draft',
    value: 'DRAFT',
    id: true,
  },
  {
    label: 'Outstanding',
    value: 'OUTSTANDING',
    id: 'OUTSTANDING',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
    id: 'APPROVED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
    id: 'REJECTED',
  },
  {
    label: 'Accounted',
    value: 'ACCOUNTED',
    id: 'ACCOUNTED',
  },
  {
    label: 'Partially Paid',
    value: 'PARTIALLY_PAID',
    id: 'PARTIALLY_PAID',
  },
  {
    label: 'Paid',
    value: 'PAID',
    id: 'PAID',
  },
];

export const INVOICE_STATUS_LIST_PRO = [
  {
    label: 'Outstanding',
    value: 'OUTSTANDING',
    id: 'OUTSTANDING',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
    id: 'APPROVED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
    id: 'REJECTED',
  },

  {
    label: 'Accounted',
    value: 'ACCOUNTED',
    id: 'ACCOUNTED',
  },
  {
    label: 'Partially Paid',
    value: 'PARTIALLY_PAID',
    id: 'PARTIALLY_PAID',
  },
  {
    label: 'Paid',
    value: 'PAID',
    id: 'PAID',
  },
];

export const PAYMENT_MODE_LIST = [
  { id: 'NET_BANKING', title: 'Net Banking', value: 'NET_BANKING' },
  { id: 'CHEQUE', title: 'Cheque', value: 'CHEQUE' },
  { id: 'CASH', title: 'Cash', value: 'CASH' },
  { id: 'UPI_WALLET', title: 'UPI/Wallet', value: 'UPI_WALLET' },
];

export const PLANNING_ITEM_TABS_TYPES = [{ id: 1, label: 'Plastics', value: 'plasticTab' }];

export const PLANNING_ITEM_CARDS_TYPES = [
  {
    id: 1,
    label: 'Unplanned Targets',
    value: 0,
    unit: 'MT',
    cardPlanningValue: 'unplanned',
    planningHeaderQty: 'unplannedTargets',
  },
  {
    id: 3,
    label: 'Planned Targets',
    value: 0,
    unit: 'MT',
    cardPlanningValue: 'planned',
    planningHeaderQty: 'plannedTargets',
  },
  {
    id: 4,
    label: 'Avail. For Replanning',
    value: 0,
    unit: 'MT',
    cardPlanningValue: 'availForReplanning',
    planningHeaderQty: 'availForReplanning',
  },
];

export const MARKETPLACE_ITEM_CARDS_TYPES = [
  { id: 1, label: 'Total Annual Credits', value: 0 },
  { id: 2, label: 'Total WOs', value: 0 },
  { id: 3, label: 'Available Credits', value: 0 },
];

export const PLANNING_ITEM_NAV_TYPES = [
  { id: 1, label: 'Unplanned', value: 'unplanned' },
  { id: 3, label: 'Planned', value: 'planned' },
  { id: 4, label: 'Avail. for Replanning', value: 'availForReplanning' },
];

export const UNPLANNED_TAB_TYPES = [
  { id: 1, label: 'Brand Work Orders', value: 'brandwiseTargets' },
];
export const PLANNED_TAB_TYPES = [
  { id: 2, label: 'Vendor Work Orders', value: 'vendorwiseTargets' },
];

export const UNPLANNED_BRAND_CARDS = [
  { id: 1, label: 'Overall Margin', value: 0, unit: '%' },
  { id: 2, label: 'Total Revenue', value: 0, unit: 'Lac' },
  { id: 3, label: 'Capacity Gap', value: 0, unit: 'MT' },
];

export const BRAND_PLANNING_TABS = [
  { id: 1, label: 'Unplanned', value: 'Unplanned' },
  { id: 2, label: 'Planned', value: 'Planned' },
];

export const VENDOR_SUPPLY_TABS = [
  { id: 1, label: 'Recyclers', value: 'recyclers' },
  { id: 2, label: 'Service Providers', value: 'serviceProviders' },
  { id: 4, label: 'Advance PO', value: 'advancePO' },
  { id: 3, label: 'Add Supply & Plan', value: 'addSupply' },
];
export const ADVANCE_PO_TABS = [
  { id: 1, label: 'Recyclers', value: 'adrecyclers' },
  { id: 2, label: 'Service Providers', value: 'adserviceProviders' },
];

export const INVOICE_TAX_LIST = [
  { id: 5, title: '5', value: 5 },
  { id: 12, title: '12', value: 12 },
  { id: 18, title: '18', value: 18 },
];

export const FULFILLMENT_CYCLE_LIST = [
  { id: 1, label: 'MONTHLY', value: 1 },
  { id: 2, label: 'QUARTERLY', value: 3 },
  { id: 3, label: 'SEMIANNUALLY', value: 6 },
  { id: 4, label: 'ANNUALLY', value: 12 },
];

export const DIRECTION = {
  RECEIVED: 'RECEIVED',
  ASSIGNED: 'ASSIGNED',
};

export const ENDORSEMENT_STATUS = {
  IN_REVIEW: { id: 'IN_REVIEW', displayText: 'Review', colors: 'red' },
  APPROVED: { id: 'APPROVED', displayText: 'Accepted', colors: 'green' },
  TO_BE_ASSIGNED: { id: 'TO_BE_ASSIGNED', displayText: 'Pending', colors: 'orange' },
  TO_BE_SIGNED: {
    id: 'TO_BE_SIGNED',
    displayText: 'Waiting to be signed',
    colors: 'yellow',
  },
  GENERATE: { id: 'GENERATE', displayText: 'Generate', colors: 'blue' },
  REJECTED: { id: 'REJECTED', displayText: 'Rejected', colors: 'red' },
};

export const SP_ULB_DOC_STATUS = [
  { id: 'TO_BE_SIGNED', displayText: 'Pending', colors: 'red' },
  { id: 'IN_REVIEW', displayText: 'Submitted', colors: 'red' },
];

export const EWASTEMANDATORYFIELDS = [
  {
    fieldName: 'pickUpInvoiceNumber',
    type: Yup.string()
      // .matches(/^[A-za-z0-9]+$/, 'invalid number')
      .required(),
  },
  {
    fieldName: 'invoiceDate',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceValue',
    type: Yup.number().required(),
  },
  {
    fieldName: 'invoiceState',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceCity',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceItemCode',
    type: Yup.string()
      // .matches(/^[A-za-z0-9]+$/, 'invalid number')
      .required(),
  },
  {
    fieldName: 'invoiceUnitRate',
    type: Yup.number().required(),
  },
  {
    fieldName: 'invoiceAmount',
    type: Yup.number().required(),
  },
  {
    fieldName: 'invoiceGstAmount',
    type: Yup.number().required(),
  },
  {
    fieldName: 'invoiceUnit',
    type: Yup.string()
      // .matches(/^[A-Za-z]+$/, 'invalid number')
      .required(),
  },
  {
    fieldName: 'eWayBillFromLocationState',
    type: Yup.string().required(),
  },
  {
    fieldName: 'eWayBillFromLocationCity',
    type: Yup.string().required(),
  },
  {
    fieldName: 'eWayBillToLocationState',
    type: Yup.string().required(),
  },
  {
    fieldName: 'eWayBillToLocationCity',
    type: Yup.string().required(),
  },
  {
    fieldName: 'eWayBillDate',
    type: Yup.string().required(),
  },
  {
    fieldName: 'form6Weight',
    type: Yup.string()
      // 1.23 Kg
      // .matches(/^[0-9aA-zZ\s\.]+$/, 'invalid number')
      .matches(/^[^;',~`!@#$%^&*()_+\-{}|:"<>?=\[\]\\\/]+$/g, 'invalid number')
      .required(),
  },
  {
    fieldName: 'invoicePurchaseOrderId',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceProductDescription',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceProductCode',
    type: Yup.string().required(),
  },
  {
    fieldName: 'brandId',
    type: Yup.string().required(),
  },
  {
    fieldName: 'pickUpWeighBridgeDate',
    type: Yup.string().required(),
  },
  {
    fieldName: 'taxRate',
    type: Yup.string().matches('^([1-9]([0-9])?|0)(.[0-9]{1,2})?$', 'Invalid percentage'),
  },
  {
    fieldName: 'eWayBillValidity',
    type: Yup.string().required(),
  },
  {
    fieldName: 'form6Number',
    type: Yup.string().required(),
  },
  {
    fieldName: 'pickUpWeighTime',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceProductCode',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoiceProductDescription',
    type: Yup.string().required(),
  },
  {
    fieldName: 'invoicePurchaseOrderId',
    type: Yup.string().required(),
  },
  {
    fieldName: 'pickUpVehicleNumber',
    type: Yup.string()
      .matches(/^[^;',.~`!@#$%^&*()_+\-{}|:"<>?=\[\]\\\/]+$/g, 'invalid number')
      .required(),
  },
];
export const CATEGORY_TAB_TYPES = [
  { id: 1, label: 'Plastic', value: 'Plastic' },
  // { id: 2, label: 'E-Waste', value: 'E-Waste' },
  // { id: 3, label: 'Industrial waste', value: 'industrialWasteTab' },
  // { id: 4, label: 'Tyres', value: 'Tyres' },
  // { id: 5, label: 'Battery', value: 'Battery' },
];

export const SOURCES_APPCODE_MAP = {
  EPR005: 'EPR',
  RPL009: 'Recycler Portal',
};

export const EWASTEMANDATORYFIELDSFORALLBRANDS = {
  brandId: Yup.string().required('Select Brand'),
  invoiceValue: Yup.number().required(),
  invoiceDate: Yup.string().required(),
  pickUpWeighBridgeDate: Yup.string().required(),
  taxRate: Yup.string().matches('^([1-9]([0-9])?|0)(.[0-9]{1,2})?$', 'Invalid percentage'),
  eWayBillValidity: Yup.string().required(),
  pickUpWeighTime: Yup.string().required(),
  pickUpVehicleNumber: Yup.string()
    .matches(/^[^;',.~`!@#$%^&*()_+\-{}|:"<>?=\[\]\\\/]+$/g, 'invalid number')
    .required('Select Vehicle Number'),
};

export const FEATURE_LIST = [
  { id: 1, label: 'Work Order Id', value: 1 },
  { id: 2, label: 'Delivery Id', value: 2 },
  { id: 3, label: 'Invoice Id', value: 3 },
];
export const PRIVILEGE_LIST_FOR_WO = [
  {
    id: 1,
    label: 'Change Price',
    value: 1,
    permissionsAllowed: [
      {
        groupName: 'Special_Permission',
        moduleName: 'Special_Permission',
        name: 'change_price_in_work_order',
      },
    ],
  },
];
export const PRIVILEGE_LIST_FOR_DL = [
  {
    id: 1,
    label: 'Status',
    value: 1,
    permissionsAllowed: [
      {
        groupName: 'Special_Permission',
        moduleName: 'Special_Permission',
        name: 'change_delivery_status',
      },
    ],
  },
  {
    id: 2,
    label: 'Attribution Month',
    value: 2,
    permissionsAllowed: [
      {
        groupName: 'Special_Permission',
        moduleName: 'Special_Permission',
        name: 'change_attribution_month',
      },
    ],
  },
  {
    id: 3,
    label: 'Delete Documents',
    value: 3,
    permissionsAllowed: [
      {
        groupName: 'Special_Permission',
        moduleName: 'Special_Permission',
        name: 'delete_document',
      },
    ],
  },
];
export const PRIVILEGE_LIST_FOR_INVOICE = [
  {
    id: 1,
    label: 'De-Link Brand Invoice',
    value: 1,
    permissionsAllowed: [
      {
        groupName: 'Special_Permission',
        moduleName: 'Special_Permission',
        name: 'delink_invoice',
      },
    ],
  },
];

export const reportArray = [
  {
    id: 1,
    label: 'Summary Report',
    value: 'summary_report',
  },
  {
    id: 2,
    label: 'Allocated Report',
    value: 'allocated_report',
  },
  {
    id: 3,
    label: 'Un Allocated Report',
    value: 'unallocated_report',
  },
  {
    id: 4,
    label: 'Allocated Report (Credits)',
    value: 'credit_report',
  },
];

export const CUSTOMER_TYPE_OPTIONS = [
  { id: 0, label: 'EPR', value: 'epr' },
  { id: 1, label: 'NON_EPR', value: 'non-epr' },
];

export const ENTITY_TYPE_OPTIONS = [
  { id: 0, label: 'Brand Owner', value: 'brand owner', enum: 'BRAND_OWNER' },
  { id: 1, label: 'Importer', value: 'importer', enum: 'IMPORTER' },
  { id: 2, label: 'Producer', value: 'producer', enum: 'PRODUCER' },
];

export const ENTITY_TYPE_DEFAULT_PIBO_VALUES = [
  {
    type: 'BRAND_OWNER',
    pwmRegistrationNumber: null,
    cpcbWalletNumber: null,
    active: true,
  },
  {
    type: 'IMPORTER',
    pwmRegistrationNumber: null,
    cpcbWalletNumber: null,
    active: true,
  },
  {
    type: 'PRODUCER',
    pwmRegistrationNumber: null,
    cpcbWalletNumber: null,
    active: true,
  },
];

export const TRACEABILITY_DOCS_OPTIONS = [
  { id: 'Yes', label: 'Yes', value: true },
  { id: 'No', label: 'No', value: false },
];

export const PROCESSING_TYPE_OPTIONS = [
  { id: 0, label: 'Recycling', value: 'RECYCLING' },
  { id: 1, label: 'End of life', value: 'END_OF_LIFE' },
];
export const CREDIT_TYPE_VALUES = {
  RECYCLING: 'Recycling',
  END_OF_LIFE: 'End of life',
  Recycling: 'Recycling',
  'End of life': 'End of life',
};

export const BUSINESS_TYPE_OPTIONS = [
  { id: 'DIRECT', label: 'Direct Business' },
  { id: 'THROUGH_SERVICE_PROVIDER', label: 'Through Service Provider' },
  { id: 'BOTH', label: 'Both' },
];

export const MANDATORY_DOCUMENTS = [
  'DROP_CERTIFICATE_OF_RECYCLING',
  'DROP_RECYCLER_CERTIFICATE',
  'DROP_CERTIFICATE_OF_CPCB',
  'DROP_CO_PROCESSING_CERTIFICATE',
];

export const HSN_OPTIONS = [
  { id: 0, label: 'SAC', value: true },
  { id: 1, label: 'HSN', value: false },
];

export const SAC = 'sac';

export const browseNodeIdList = [
  { id: 1, nodeId: 1 },
  { id: 2, nodeId: 2 },
  { id: 3, nodeId: 3 },
  { id: 4, nodeId: 4 },
  { id: 5, nodeId: 5 },
  { id: 6, nodeId: 6 },
];

export const CREDIT_EXECUTION_TYPES = [
  { id: 1, label: 'SELF', value: 'DIRECT' },
  { id: 2, label: 'SERVICE_PROVIDER', value: 'THROUGH_SERVICE_PROVIDER' },
  { id: 3, label: 'BOTH', value: 'BOTH' },
];

export const INVOICE_METRICS = {
  'Total invoiced Amount': 62400,
  'Manual invoiced Amount': 35000,
  'Credit Note': 46700,
  'Debit Note': 1280,
  'Payment Received': 78966,
  'Payment Pending': 45000,
};

export const groupedOptions = [
  {
    type: 'businessNames',
    value: 'businessNames',
    title: 'Search Business Name',
    label: 'Search Business Name',
    placeholder: 'Enter Business Name',
    id: 'businessNames',
  },
  {
    type: 'panNos',
    value: 'panNos',
    title: 'Search PAN',
    label: 'Search PAN',
    placeholder: 'Enter PAN',
    id: 'pan.contains',
  },
  {
    type: 'contactPersons',
    value: 'contactPersons',
    title: 'Search Contact Person',
    label: 'Search Contact Person',
    placeholder: 'Enter Contact Person Name',
    id: 'contactPersonName.equals',
  },
  {
    type: 'phoneNumbers',
    value: 'phoneNumbers',
    title: 'Search Phone Number',
    label: 'Search Phone Number',
    placeholder: 'Enter Phone Number',
    id: 'mobile.equals',
  },
  {
    type: 'emails',
    value: 'emails',
    title: 'Search Email',
    label: 'Search Email',
    placeholder: 'Enter Email Id',
    id: 'email.equals',
  },
  {
    type: 'SPOC Name',
    value: 'SPOCName',
    title: 'Search SPOC Name',
    label: 'Search SPOC Name',
    placeholder: 'Enter SPOC Name',
    id: 'spocName.contains',
  },
];
export const INVOICES_TYPES_MAP = {
  'ADVANCED INVOICE': 'Advance',
  'REGULAR INVOICE': 'Regular',
};

export const DELIVERY_INVOICE_STATUS_MAP = {
  'INVOICE MAPPED': 'Invoice mapped',
  'PROFORMA GENERATED': 'Profroma Generated',
  'INVOICE GENERATED': 'Invoice Generated',
};

export const VerticalOptions = [
  { label: 'PLASTIC', id: 0, value: 'Plastic' },
  { label: 'E_WASTE', id: 1, value: 'E-waste' },
  { label: 'TYRES', id: 2, value: 'Tyres' },
  { label: 'BATTERIES', id: 3, value: 'Batteries' },
];
export const VERTICALS_MAP = {
  PLASTIC: { label: 'PLASTIC', id: 0, value: 'Plastic' },
  E_WASTE: { label: 'E_WASTE', id: 1, value: 'E-waste' },
  TYRES: { label: 'TYRES', id: 2, value: 'Tyres' },
  BATTERIES: { label: 'BATTERIES', id: 3, value: 'Batteries' },
};

export const WITH_EWAY_BILL_DOCS = [
  'PICKUP_E_WAY_BILL',
  'PICKUP_WB',
  'DROP_OFF_WB',
  'PICKUP_INVOICE',
];

export const WITHOUT_EWAY_BILL_DOCS = ['PICKUP_WB', 'DROP_OFF_WB', 'PICKUP_INVOICE'];

export const DOC_CATEGORY_BULK = [
  {
    type: 'SELECT',
    title: 'Select Name',
    required: false,
  },
  {
    type: 'PICKUP_WB',
    title: 'Pickup Weighbridge',
    required: true,
  },
  {
    type: 'PICKUP_E_WAY_BILL',
    title: 'E-Way Bill',
    required: true,
  },
  {
    type: 'PICKUP_INVOICE',
    title: 'Invoice',
    required: true,
  },
  {
    type: 'PICKUP_LR',
    title: 'Lorry Receipt',
    required: true,
  },
  {
    type: 'PICKUP_OTHERS',
    title: 'Others',
    required: true,
  },
  {
    type: 'DROP_OFF_WB',
    title: 'Dropoff Weighbridge',
    required: true,
  },
];

export const DOC_TYPE_MAP_BULK = {
  PICKUP_WB: 'Pickup Weighbridge',
  PICKUP_INVOICE: 'Invoice',
  DROP_OFF_WB: 'Dropoff Weighbridge',
  PICKUP_LR: 'Lorry Receipt',
  PICKUP_OTHERS: 'Others',
  PICKUP_E_WAY_BILL: 'E-Way Bill',
};

export const DISPUTE_REASON_OPTIONS = [
  {
    label: 'Document Missing',
    id: 1,
    value: 'Document Missing',
  },
  {
    label: 'Document Mismatch',
    id: 2,
    value: 'Document Mismatch',
  },
  {
    label: 'Other',
    id: 3,
    value: null,
  },
];

export const PERMISSIONS_GROUP_NAMES = {
  DELIVERY: 'Delivery',
  MANAGE_VENDORS: 'Manage_Vendors',
  MANAGE_CREDITS: 'Manage_Credits',
  PLANNING: 'Planning',
  FINANCE: 'Finance',
  EXECUTE_TARGETS: 'Execute_Targets',
  REPORTS: 'Reports',
  SPECIAL_PERMISSION: 'Special_Permission',
  USER_MANAGEMENT: 'User_Management',
  SETTING: 'Setting',
};

export const PERMISSIONS_MODULE_NAME = {
  BULK_UPLOAD: 'Bulk_Upload',
  DRAFT_DELIVERY: 'Draft_Delivery',
  DELIVERY: 'Delivery',
  VENDOR_DELIVERY: 'Delivery',
  BRAND_DELIVERY: 'Brand_Delivery',
  SERVICE_PROVIDERS: 'Service_Providers',
  PAYABLES_INVOICE: 'Payables_Invoice',
  WORK_ORDERS: 'Work_Orders',
  PAYABLES_PAYMENTS: 'Payables_Payments',
  MANAGE_CREDITS: 'Manage_Credits',
  PLANNING: 'Planning',
  RECEIVABLES: 'Receivables',
  CLIENTS: 'Clients',
  MONTHLY_FULFILLMENT: 'Monthly_Fulfillment',
  SELF_DECLARATION: 'Self_Declaration',
  'RECYCLING/CO-PROCESSOR_CERTIFICATE': 'Recycling/Co-Processor_Certificate',
  SPCB: 'SPCB',
  EPR_REPORT: 'EPR_Report',
  SPECIAL_PERMISSION: 'Special_Permission',
  USERS: 'Users',
  ROLES: 'Roles',
  SETTING: 'Setting',
};

export const PERMISSIONS_NAME = {
  FULL_ACCESS: 'full_access',
  CAN_CREATE: 'can_create',
  CAN_DELETE: 'can_delete',
  CAN_VIEW: 'can_view',
  CAN_EDIT: 'can_edit',
  MAP_THE_TRANSACTIONS_TO_WORK_ORDER: 'map_the_transactions_to_work_order',
  DOWNLOAD_TRANSACTIONS: 'download_transactions',
  APPROVE_DOCUMENTS: 'approve_documents',
  DISPUTE_DOCUMENTS: 'dispute_documents',
  AUDIT: 'audit',
  APPROVE_DELIVERY: 'approve_delivery',
  DISPUTE_DELIVERY: 'dispute_delivery',
  DELETE_DOCUMENT: 'delete_document',
  RE_TAG_DOCUMENT: 're_tag_document',
  UPLOAD_DOCUMENT: 'upload_document',
  ALLOCATE_TO_BRAND: 'allocate_to_brand',
  CHANGE_ALLOCATION: 'change_allocation',
  SEND_SMS_E_WASTE: 'send_sms_e_waste',
  CREATE_PV_IN_ZOHO_E_WASTE: 'create_pv_in_zoho_e_waste',
  DOWNLOAD_DELIVERIES: 'download_deliveries',
  DOWNLOAD_DELIVERY: 'download_delivery',
  DOWNLOAD_DOCUMENT_DUMP: 'download_document_dump',
  ADD_RECYCLER: 'add_recycler',
  ADD_SERVICE_PROVIDER: 'add_service_provider',
  LINK_SERVICE_PROVIDERS_TO_RECYCLERS: 'link_service_providers_to_recyclers',
  DOWNLOAD_TEMPLATE: 'download_template',
  ADD_INVENTORY: 'add_inventory',
  EDIT_INVENTORY: 'edit_inventory',
  DELETE_INVENTORY: 'delete_inventory',
  DOWNLOAD_INVOICES: 'download_invoices',
  APPROVE_INVOICES: 'approve_invoices',
  REJECT_INVOICES: 'reject_invoices',
  DOWNLOAD_WORK_ORDERS: 'download_work_orders',
  DOWNLOAD_DOCUMENTS: 'download_documents',
  DOWNLOAD_DOCUMENT: 'download_document',
  CREATE_WORK_ORDER: 'create_work_order',
  CLOSE_WORK_ORDER: 'close_work_order',
  DRAFT_WO_VIEW: 'draft_wo_view',
  DRAFT_WO_EDIT: 'draft_wo_edit',
  DELETE_DRAFT_WO: 'delete_draft_wo',
  RESEND_CONSENT: 'resend_consent',
  PLANNED_VIEW: 'planned_view',
  PLANNED_EDIT: 'planned_edit',
  FREE_WO_QUANTITY: 'free_wo_quantity',
  CHANGE_BRAND_MAPPING: 'change_brand_mapping',
  CHANGE_ALLOCATION_OF_DELIVERIES: 'change_allocation_of_deliveries',
  ADD_ADVANCE_INVOICE: 'add_advance_invoice',
  GENERATE_PROFORMA_INVOICE: 'generate_proforma_invoice',
  MAP_THE_DELIVERIES: 'map_the_deliveries',
  APPROVE_PROFORMA: 'approve_proforma',
  EDIT_PROFORMA: 'edit_proforma',
  APPROVE_INVOICE: 'approve_invoice',
  ADD_PAYMENT_AND_ASSIGN_INVOICE: 'add_payment_and_assign_invoice',
  AUDIT_TRAIL: 'audit_trail',
  APPROVE_REJECT_WO: 'approve_reject_wo',
  APPROVE_REJECT_CREDITS: 'approve_reject_credits',
  EXPORT: 'export',
  SEND_FULFILLMENT_PLAN: 'send_fulfillment_plan',
  DOWNLOAD_THE_BRAND_PREVIEW: 'download_the_brand_preview',
  UPLOAD_SIGNED: 'upload_signed',
  DOWNLOAD_SIGNED: 'download_signed',
  DOWNLOAD_DRAFT: 'download_draft',
  RE_UPLOAD_SIGNED: 're_upload_signed',
  DELETE_DRAFT_CERTIFICATES: 'delete_draft_certificates',
  DOWNLOAD_SPCB: 'download_spcb',
  ADD_SPCB: 'add_spcb',
  MODIFY_SPCB: 'modify_spcb',
  DOWNLOAD_REPORT_OF_DELIVERIES: 'download_report_of_deliveries',
  CHANGE_PRICE_IN_WORK_ORDER: 'change_price_in_work_order',
  CHANGE_DELIVERY_STATUS: 'change_delivery_status',
  CHANGE_ATTRIBUTION_MONTH: 'change_attribution_month',
  DELINK_INVOICE: 'delink_invoice',
  DISABLE_USER: 'disable_user',
  EDIT_BUSSINESS_INFO: 'edit_bussiness_info',
  EDIT_CONTACT_DETAILS: 'edit_contact_details',
  UPLOAD_LOGO: 'upload_logo',
  IN_PROGRESS: 'in_progress',
  CAN_DELETE_DOCUMENT: 'can_delete_document',
  DOWNLOAD_REPORTS: 'download_reports',
  ALL_BRANDS: 'all_brands',
  BULK_DELETE: 'bulk_delete',
  TRANSACTION_DRAFT_VIEW: 'transaction_draft_view',
  TRANSACTION_DRAFT_EDIT: 'transaction_draft_edit',
  TRANSACTION_IN_REVIEW_VIEW: 'transaction_in_review_view',
  TRANSACTION_IN_REVIEW_EDIT: 'transaction_in_review_edit',
  TRANSACTION_IN_REVIEW_DELETE_DOCUMENTS: 'transaction_in_review_delete_documents',
  TRANSACTION_APPROVED_VIEW: 'transaction_approved_view',
  ALLOCATE_APPROVED_TRANSACTION: 'allocate_approved_transaction',
  TRANSACTION_REJECTED_VIEW: 'transaction_rejected_view',
  TRANSACTION_REJECTED_EDIT: 'transaction_rejected_edit',
  TRANSACTION_REJECTED_DELETE_DOCUMENTS: 'transaction_rejected_delete_documents',
  TRANSACTION_ALLOCATED_VIEW: 'transaction_allocated_view',
};

export const DELIVERY_DISPLAY_STATUS = {
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    displayText: 'In progress',
    bg: '#EFF8FF',
    borderColor: '#B2DDFF',
    textColor: '#175CD3',
  },
  IN_REVIEW: {
    id: 'IN_REVIEW',
    displayText: 'In Review',
    bg: '#FFFAEB',
    borderColor: '#FEDF89',
    textColor: '#B54708',
  },
  APPROVED: {
    id: 'APPROVED',
    displayText: 'Approved',
    bg: '#ECFDF3',
    borderColor: '#ABEFC6',
    textColor: '#067647',
  },
};

export const BRAND_WO_STATUS_WITHOUT_COLUMNS = ['IN_REVIEW', 'DRAFT', 'REJECTED'];

export const BILL_TYPES = [
  {
    id: 'CREDITS_BASED',
    label: 'Credits Based',
    value: 'CREDITS_BASED',
  },
  {
    id: 'DELIVERY_BASED',
    label: 'Delivery Based',
    value: 'DELIVERY_BASED',
  },
];

export const BUSINESS_TYPES_OPTIONS = [
  {
    id: 1,
    name: 'Through service provider',
    value: 'THROUGH_SERVICE_PROVIDER',
    business: 'THROUGH_SERVICE_PROVIDER',
  },
  {
    id: 2,
    name: 'Direct',
    value: 'DIRECT',
    business: 'DIRECT',
  },
];

export const BRAND_INVOICE_SPECIFICATION = [
  { id: 'HO:NONE', displayText: 'Head-office/Null' },
  { id: 'HO:CATEGORY', displayText: 'Head-office/category' },
  { id: 'STATE:NONE', displayText: 'State/Null' },
  { id: 'STATE:CATEGORY', displayText: 'State/Category' },
];
export const BRAND_WO_CARD_KEYS = {
  APPROVED: 'APPROVED',
  IN_REVIEW: 'IN_REVIEW',
  TOTAL: 'TOTAL',
  DRAFT: 'DRAFT',
  REJECTED: 'REJECTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  COMPLETEDCLOSED: 'COMPLETEDCLOSED',
};
export const poOptions = [
  {
    id: 'Y',
    name: 'Y',
    value: 'Y',
  },
  {
    id: 'N',
    name: 'N',
    value: 'N',
  },
];

export const roles = {
  BRAND: 'BRAND_OWNER',
  IMPORTER: 'IMPORTER',
  PRODUCER: 'PRODUCER',
};

export function categoryName(name) {
  switch (name) {
    case 'Category 1 : Rigid':
      return 'CAT1';
    case 'Batteries':
      return 'BATTERIES';
    case 'Category 2 : Flexible':
      return 'CAT2';
    case 'Category 3':
      return 'CAT3';
    case 'Category 4':
      return 'CAT4';
    case 'E-Waste':
      return 'EWASTE';
    case 'Tyres':
      return 'TYRES';
    default:
      return '';
  }
}

export const processingTypes = {
  recycler: 'PWP',
  coProcessor: 'END_OF_LIFE',
};

export const PO_DISPLAY_STATUS = {
  draft: {
    id: 'DRAFT',
    displayText: 'Draft',
    value: 'DRAFT',
    bg: '#F9FAFB',
    borderColor: '#EAECF0',
    textColor: '#344054',
  },
  approved: {
    id: 'APPROVED',
    displayText: 'Approved',
    value: 'APPROVED',
    bg: '#ECFDF3',
    borderColor: '#ABEFC6',
    textColor: '#067647',
  },
  in_review: {
    id: 'IN_REVIEW',
    displayText: 'In-Review',
    value: 'IN_REVIEW',
    bg: '#FFFAEB',
    borderColor: '#FEDF89',
    textColor: '#B54708',
  },
  partially_billed: {
    id: 'PARTIALLY_BILLED',
    displayText: 'Partially Billed',
    value: 'PARTIALLY_BILLED',
    bg: '#EFF8FF',
    borderColor: '#B2DDFF',
    textColor: '#175CD3',
  },
  billed: {
    id: 'BILLED',
    displayText: 'Billed',
    value: 'BILLED',
    bg: '#F4F3FF',
    borderColor: '#D9D6FE',
    textColor: '#5925DC',
  },
  pending_approval: {
    id: 'PENDING_APPROVAL',
    displayText: 'Pending Approval',
    value: 'PENDING_APPROVAL',
    bg: '#FEF3F2',
    borderColor: '#FECDCA',
    textColor: '#B42318',
  },
  open: {
    id: 'OPEN',
    displayText: 'Open',
    value: 'OPEN',
    bg: '#FEF3F2',
    borderColor: '#FECDCA',
    textColor: '#B42318',
  },
  void: {
    id: 'VOID',
    displayText: 'Void',
    value: 'VOID',
    bg: '#B42318',
    borderColor: '#FECDCA',
    textColor: '#FEF3F2',
  },
  rejected: {
    id: 'REJECTED',
    displayText: 'Rejected',
    value: 'REJECTED',
    bg: '#B42318',
    borderColor: '#FECDCA',
    textColor: '#FEF3F2',
  },
  cancelled: {
    id: 'CANCELLED',
    displayText: 'Cancelled',
    value: 'CANCELLED',
    bg: '#B42318',
    borderColor: '#FECDCA',
    textColor: '#FEF3F2',
  },
};

export const CATEGORY_TYPES = [
  { id: 'CAT1', label: 'CAT 1' },
  { id: 'CAT2', label: 'CAT 2' },
  { id: 'CAT3', label: 'CAT 3' },
  { id: 'CAT4', label: 'CAT 4' },
];

export const VENDOR_TYPES_OPTIONS = [
  { id: 'RECYCLER', label: 'RECYCLER', value: 'Recycler' },
  { id: 'SERVICE_PROVIDER', label: 'SERVICE PROVIDER', value: 'Service Provider' },
];

export const vendorTypes = {
  recycler: 'RECYCLER',
  serviceProvider: 'SERVICE_PROVIDER',
};

export const poTypes = {
  advancePo: 'Advance',
  regular: 'Regular',
};

export const PO_TYPE_OPTIONS = [
  { id: 'Advance', value: 'Advance' },
  { id: 'Regular', value: 'Regular' },
];

export const PO_STATUSES = [
  { id: 'open', label: 'Open', value: 'open' },
  { id: 'draft', label: 'Draft', value: 'draft' },
  { id: 'in_review', label: 'In-Review', value: 'in_review' },
  { id: 'pending_approval', label: 'Pending Approval', value: 'pending_approval' },
  { id: 'approved', label: 'Approved', value: 'approved' },
  { id: 'partially_billed', label: 'Partially Billed', value: 'partially_billed' },
  { id: 'billed', label: 'Billed', value: 'billed' },
];

export const CREDIT_DISPLAY_STATUS = {
  APPROVED: {
    id: 'APPROVED',
    displayText: 'Approved',
    bg: '#ECFDF3',
    borderColor: '#ABEFC6',
    textColor: '#067647',
  },
  REJECTED: {
    id: 'REJECTED',
    displayText: 'Rejected',
    bg: '#FEF3F2',
    borderColor: '#FECDCA',
    textColor: '#B42318',
  },
  IN_REVIEW: {
    id: 'IN_REVIEW',
    displayText: 'In Review',
    bg: '#FFFAEB',
    borderColor: '#FEDF89',
    textColor: '#B54708',
  },
  DRAFT: {
    id: 'DRAFT',
    displayText: 'Draft',
    bg: '#F9FAFB',
    borderColor: '#EAECF0',
    textColor: '#344054',
  },
};

export const BRAND_WORK_ORDER_CARDS = {
  workOrderQty: 'WO Qty',
  plannedQty: 'Planned Qty',
  fullfilledQty: 'Fulfilled Qty',
  creditTransferred: 'Credit Transferred',
  workOrderValue: 'WO Value',
};
export const CREDIT_STATUS = {
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  UPDATE: 'UPDATED',
};

export const DELIVERY_TRANSACTION_STATUS = {
  DRAFT: {
    id: 'DRAFT',
    label: 'Draft',
    bg: '#F9FAFB',
    borderColor: '#EAECF0',
    color: '#344054',
  },
  IN_REVIEW: {
    id: 'IN_REVIEW',
    label: 'In Review',
    background: '#FFFAEB',
    borderColor: '#FEDF89',
    color: '#B54708',
  },
  APPROVED: {
    id: 'APPROVED',
    label: 'Approved',
    background: '#ECFDF3',
    borderColor: '#ABEFC6',
    color: '#067647',
  },
  REJECTED: {
    id: 'REJECTED',
    label: 'Rejected',
    background: '#FEE2E2',
    borderColor: '#FECACA',
    color: '#C53030',
  },
  ALLOCATED: {
    id: 'ALLOCATED',
    label: 'Allocated',
    background: '#EFF8FF',
    borderColor: '#B2DDFF',
    color: '#175CD3',
  },
};

export const DELIVERY_TRANSACTION_STATUS_LIST = [
  {
    id: 'DRAFT',
    label: 'Draft',
    bg: '#F9FAFB',
    borderColor: '#EAECF0',
    color: '#344054',
  },
  {
    id: 'IN_REVIEW',
    label: 'In Review',
    background: '#FFFAEB',
    borderColor: '#FEDF89',
    color: '#B54708',
  },
  {
    id: 'APPROVED',
    label: 'Approved',
    background: '#ECFDF3',
    borderColor: '#ABEFC6',
    color: '#067647',
  },
  {
    id: 'REJECTED',
    label: 'Rejected',
    background: '#FEE2E2',
    borderColor: '#FECACA',
    color: '#C53030',
  },
  {
    id: 'ALLOCATED',
    label: 'Allocated',
    background: '#EFF8FF',
    borderColor: '#B2DDFF',
    color: '#175CD3',
  },
];

export const PO_LIST_SUMMARY_CARDS = {
  total: 'TOTAL',
  planned: 'PLANNED',
  unPlanned: 'UN_PLANNED',
  billed: 'BILLED',
};

export const BULK_DELIVERY_TRANSACTION_OPERATIONS = {
  UPDATED: {
    id: 'UPDATED',
    displayText: 'Update',
  },
  IN_REVIEW: {
    id: 'IN_REVIEW',
    displayText: 'In Review',
  },
  APPROVED: {
    id: 'APPROVED',
    displayText: 'Approved',
  },
  REJECTED: {
    id: 'REJECTED',
    displayText: 'Rejected',
  },
  DOCUMENT_APPROVED: {
    id: 'DOCUMENT_APPROVED',
    displayText: 'Document Approved',
  },
  DOCUMENT_UPLOADED: {
    id: 'DOCUMENT_UPLOADED',
    displayText: 'Document Uploaded',
  },
  DOCUMENT_TAGGED: {
    id: 'DOCUMENT_TAGGED',
    displayText: 'Document Tagged',
  },
  DOCUMENT_REJECTED: {
    id: 'DOCUMENT_REJECTED',
    displayText: 'Document Rejected',
  },
  DOCUMENT_DELETED: {
    id: 'DOCUMENT_DELETED',
    displayText: 'Document Deleted',
  },
  ALLOCATED: {
    id: 'ALLOCATED',
    displayText: 'Allocated',
  },
};

export const INVOICE_MG_SUMMARY_CARDS = [
  {
    id: 1,
    name: 'Target Quantity',
    value: 'targetQuantity',
  },
  {
    id: 2,
    name: 'Fulfilled Quantity',
    value: 'fulfilledQuantity',
  },
  {
    id: 3,
    name: 'Credits Received',
    value: 'creditsQuantity',
  },
  {
    id: 4,
    name: 'Invoiced Quantity',
    value: 'invoicedQuantity',
  },
  {
    id: 5,
    name: 'Invoiced Today',
    value: 'todayInvoicedQuantity',
  },
];

export const INVOICE_MG_STATUS = [
  {
    id: 'READY_FOR_INVOICING',
    label: 'Ready for Invoicing',
    value: 'READY_FOR_INVOICING',
  },
  {
    id: 'PENDING',
    label: 'Pending',
    value: 'PENDING',
  },
  {
    id: 'INVOICED',
    label: 'Invoiced',
    value: 'INVOICED',
  },
  {
    id: 'PARTIALLY_INVOICED',
    label: 'Partially Invoiced',
    value: 'PARTIALLY_INVOICED',
  },
];

export const INVOICE_MG_DISPLAY_STATUS = {
  READY_FOR_INVOICING: {
    id: 'READY_FOR_INVOICING',
    label: 'Ready for Invoicing',
    value: 'READY_FOR_INVOICING',
    displayText: 'Ready for Invoicing',
    bg: '#FFFAEB',
    borderColor: '#FEDF89',
    textColor: '#B54708',
  },
  PENDING: {
    id: 'PENDING',
    label: 'Pending',
    value: 'PENDING',
    displayText: 'Pending',
    bg: '#F9FAFB',
    borderColor: '#EAECF0',
    textColor: '#344054',
  },
  INVOICED: {
    id: 'INVOICED',
    label: 'Invoiced',
    value: 'INVOICED',
    displayText: 'Invoiced',
    bg: '#ECFDF3',
    borderColor: '#ABEFC6',
    textColor: '#067647',
  },
  PARTIALLY_INVOICED: {
    id: 'PARTIALLY_INVOICED',
    label: 'Partially Invoiced',
    value: 'PARTIALLY_INVOICED',
    displayText: 'Partially Invoiced',
    bg: '#EFF8FF',
    borderColor: '#B2DDFF',
    textColor: '#175CD3',
  },
};
