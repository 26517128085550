import classNames from 'classnames';
import React from 'react';
import Select from 'react-select';
import { useRegions } from '../../../hooks';

const DropdownState = ({ isD1 = false, ...props }) => {
  let states = useRegions();

  if (isD1) {
    states.shift();
  }

  if (props.filterIds && props.filterIds?.length > 0) {
    states = states?.filter((state) => props.filterIds.indexOf(state.id) >= 0);
  }

  return (
    <>
      <Select
        {...props}
        className={classNames('react-select', {
          'is-invalid': props.isInvalid,
        })}
        classNamePrefix="select"
        value={
          typeof props.value !== 'undefined' &&
          props.value &&
          states?.find(
            (o) =>
              o?.id === props?.value ||
              o?.id === props?.value?.sourceState ||
              o?.name === props?.value ||
              o?.name === props?.value?.sourceState
          )
        }
        options={states}
        getOptionLabel={(option) => option?.name}
        getOptionValue={(option) => option?.id}
        noOptionsMessage={() => 'States not found!'}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </>
  );
};

export default DropdownState;
