/* eslint-disable react-hooks/exhaustive-deps */
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import PaginationComponent from '../../../features/Customers/PaginationComponent';
import { Loader } from '../../../shared/Components';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import { AUTHORITY2, PAGE_CONFIG, pageOptions } from '../../../shared/Constants/Constants';

function BulkDeliveryTable({
  DATA,
  TABLEPARAM,
  COLUMNDEF,
  tableStateFn,
  pageChangeFn,
  setSelectedTxns,
  isPaginate = true,
  isLoading,
  isMapWO,
  filterApplied,
  handleSort,
  handleFilterChange,
  columnsWithFilter = [],
  tabStatus,
  setFilters,
  selectedTab,
  type,
  sortBy,
}) {
  const NoOfPages = Math.ceil(TABLEPARAM?.total / (isMapWO ? 10 : PAGE_CONFIG.size));
  const PAGE_SIZE = isMapWO ? 10 : PAGE_CONFIG.size;
  const { hasAuth } = useAuthority();
  const [isFirstRender, setIsFirstRender] = React.useState(true);

  const isServiceProvider = hasAuth([AUTHORITY2.SERVICE_PROVIDER]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    state: { pageIndex, filters },
    selectedFlatRows,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    gotoPage,
    setAllFilters,
  } = useTable(
    {
      columns: COLUMNDEF,
      data: DATA,
      autoResetExpanded: false,
      autoResetSelectedRows: true,
      initialState: {
        pageSize: PAGE_SIZE,
        pageIndex: TABLEPARAM?.pageKey ?? 0,
        hiddenColumns: [isServiceProvider && 'username', isServiceProvider && 'userName'],
      },
      manualPagination: true,
      manualFilters: true,
      pageCount: NoOfPages,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (!isEmpty(filters)) {
      setAllFilters([]);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (handleFilterChange && !isFirstRender) {
      if (!!type && type?.toLowerCase() === selectedTab.toLowerCase()) handleFilterChange(filters);
      if (!type) {
        handleFilterChange(filters);
      }
    }
    setIsFirstRender(false);
  }, [filters]);

  useEffect(() => {
    if (sortBy) {
      handleFilterChange(filters);
    }
  }, [sortBy]);

  useEffect(() => {
    if (isMapWO && setSelectedTxns !== undefined) {
      const selectedTxns = selectedFlatRows?.filter((item) => item?.isSelected);
      const selectedTxnValues = selectedTxns?.map((txn) => txn?.values);
      setSelectedTxns(selectedTxnValues);
    }
  }, [selectedFlatRows?.length]);

  useEffect(() => {
    if (tableStateFn !== undefined) {
      tableStateFn((prev) => ({ ...prev, pageKey: pageIndex ?? 0 }));
    }
  }, [TABLEPARAM?.pages]);

  return (
    <>
      <Table
        {...getTableProps()}
        responsive
        className="bulk-delivery-table rcyl-table1 p-0 table-bordered border-bottom-0 work-order-items-edit"
        style={{ borderCollapse: 'separate', borderSpacing: '0px' }}>
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      className={
                        (column.isSticky ? 'sticky' : '') +
                        (column.isLargeColumn ? ' sticky-large' : '') +
                        (column.isTooltipColumn ? ' is-tooltip-column' : '')
                      }
                      {...column.getHeaderProps()}>
                      <div
                        className={
                          'd-flex flex-row align-items-center gap-2' +
                          (column.isCentered ? ' justify-content-center' : '')
                        }>
                        {column.render('Header', rows)}
                        {column.canSort && column.id !== 'check' ? (
                          <span className={'ml-auto mr-1'}>
                            <div className="icons-section">
                              <div className={'column-sort'}>
                                <FontAwesomeIcon
                                  className="pointer"
                                  onClick={
                                    handleSort ? () => handleSort(column.id, 'desc') : () => {}
                                  }
                                  icon={faCaretUp}
                                />
                              </div>
                              <div
                                className="column-sort"
                                style={{
                                  marginTop: '-10px',
                                }}>
                                <FontAwesomeIcon
                                  className="pointer"
                                  onClick={
                                    handleSort ? () => handleSort(column.id, 'asc') : () => {}
                                  }
                                  icon={faCaretDown}
                                />
                              </div>
                            </div>
                          </span>
                        ) : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
              {columnsWithFilter?.length ? (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                  {headerGroup.headers.map((column) => {
                    const renderFilter = () => {
                      if (!columnsWithFilter.includes(column?.id)) {
                        return null;
                      }
                      return column.render('Filter');
                    };

                    return (
                      <th
                        className={
                          (column.isSticky ? 'sticky' : '') +
                          (column.isLargeColumn ? ' sticky-large' : '') +
                          (column.isTooltipColumn ? ' is-tooltip-column' : '')
                        }>
                        <div>{columnsWithFilter?.includes(column?.id) ? renderFilter() : ''}</div>
                      </th>
                    );
                  })}
                </tr>
              ) : null}
            </>
          ))}
        </thead>
        {isLoading ? (
          <></>
        ) : (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()} key={`main${i}`}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={
                          (cell.column.isSticky ? 'sticky' : '') +
                          (cell.column.isLargeColumn ? ' sticky-large' : '') +
                          (cell.column.isTooltipColumn ? ' is-tooltip-column' : '')
                        }
                        {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>

      {isLoading && (
        <div className="d-flex w-100 justify-content-center align-items-center">
          <Loader />
        </div>
      )}

      {pageOptions?.length > 1 && isPaginate ? (
        <PaginationComponent
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={() => {
            previousPage();
            pageChangeFn(pageIndex - 1, filters);
          }}
          nextPage={() => {
            nextPage();
            pageChangeFn(pageIndex + 1, filters);
          }}
          gotoPage={(page) => {
            gotoPage(page);
            pageChangeFn(page, filters);
          }}
          currentPage={TABLEPARAM?.pageKey ?? 0}
          numberOfPages={NoOfPages}
          totalCount={TABLEPARAM?.total}
          pageSize={isMapWO ? 10 : PAGE_CONFIG.size}
        />
      ) : (
        isPaginate && <p>Total Count: {filterApplied ? rows?.length : TABLEPARAM?.total}</p>
      )}
    </>
  );
}

export default BulkDeliveryTable;
